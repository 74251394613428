import s from './remove-appointments-note.module.scss'
import { Modal } from 'shared/modal/modal';
import { Button } from 'shared/button/button';
import { useRemoveAppointmentNote } from '../../../../hooks/useRemoveAppointmentNote';

export const RemoveAppointmentsNoteModal = ({isOpen, setOpen, modalParams}) => {
	const appointmentId = modalParams?.appointmentId
	const noteId = modalParams?.noteId
	const {mutate: remove} = useRemoveAppointmentNote(appointmentId)
	
	const removeNote = () => {
		remove({appointmentId, noteId})
		setOpen()
	}

  return (
		<Modal {...{isOpen, setOpen}} name={'remove-appointments-note'}>
			<div className={s.card}>
					<div className={s.title}>Вы уверены?</div>
					<div className={s.text}>Вы точно хотете удалить заметку?</div>
					<div className={s.btns}>
						<Button
							theme='secondary'
							className={s.btn}
							onClick={() => setOpen()}
							label={'Нет'}
						/>
						<Button
							className={s.btn}
							onClick={removeNote}
							label={'Да'}
						/>
					</div>
			</div>
		</Modal>
  );
};