import React, { useState } from "react";
import s from "./appointment-teacher.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { IconChevronLeft } from "@tabler/icons-react";
import classNames from "classnames";
import { Button } from "../../../components/shared/button/button";
import { useAppointment } from "../../../hooks/useAppointment";
import { AppointmentNote } from "../../../components/teacher-profile/appointment-note/appointment-note";
import { AddNoteCard } from "../../../components/teacher-profile/add-note-card/add-note-card";
import { AddHomeworkCard } from '../../../components/teacher-profile/add-homework-card/add-homework-card';
import { HomeworkCard } from "../../../components/teacher-profile/homework-card/homework-card";
import { Tooltip } from "react-tooltip";
import { useAppointmentToken } from '../../../hooks/useAppointmentToken';
import { VideoConf } from "../../../components/shared/video-conf/video-conf";
import { useMedia } from "use-media";
import { LiveKitRoom } from "@livekit/components-react";
import { Loader } from 'shared/loader/loader';

const Toggle = ({ value, onChange, label }) => {
  return (
		<div className={s.toggleFlex}>
      {label && <p className={s.toggleLabel}>{label}</p> }
			<div className={`${s.toggleWrapper} ${value ? s.active : ''}`} onClick={() => onChange(p => !p)}>
				<span onClick={() => onChange(p => !p)}></span>
			</div>
		</div>
  );
};

export const AppointmentTeacher = () => {
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const [mode, setMode] = useState(0);
  const { data: appointment, isFetching: appointmentIsFetching } = useAppointment(appointmentId);
	const [isConf, setIsConf] = useState(false)
	const [isConfVisible, setIsConfVisible] = useState(false)
	const isMobile = useMedia({maxWidth: '768px'})

	const date = new Date(appointment?.starts_at);
  const today = new Date();

	const timeToRequest = new Date(date.getTime() - 15 * 60 * 1000);
	const shouldRequest = today >= timeToRequest;
	const isToday = date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()

	const { data: appointment_token, status } = useAppointmentToken({id: appointmentId, shouldRequest});

	const disabled = status === "pending" || status === "error"

  return (
    <LiveKitRoom
			video={true}
			audio={true}
			token={appointment_token}
			onDisconnected={() => setIsConf(false)}
			serverUrl={process.env.REACT_APP_LIVE_KIT_SERVER_URL}
			data-lk-theme="default"
			className={s.wrapper}
			connect={isConf}
		>
      <button className={s.backToList} onClick={() => navigate(-1)}>
        <IconChevronLeft /> Назад к списку занятий
      </button>
			{isMobile ? <Toggle label={'Видеоконференция'} value={isConfVisible} onChange={setIsConfVisible}/> : null}

      <div className={s.flexWrapper}>
        {(isMobile && isConfVisible) ? null :<div className={s.appointmentColumn}>
          <div className={s.appointmentHeader}>
            <div className={s.title}>Занятие</div>
            <div className={classNames(s.tabs, mode === 0 ? s.active : "")}>
              <button
                className={classNames(s.tab, mode === 0 ? s.active : "")}
                onClick={() => setMode(0)}
              >
								
                Материалы занятия
              </button>
              <button
                className={classNames(s.tab, mode === 1 ? s.active : "")}
                onClick={() => setMode(1)}
              >
                Домашнее задание
								{appointment?.has_unreviewed_homework ? <span className={s.unread} data-tooltip-id='tooltip-has-new-homework-result'></span> : ''}
              </button>
            </div>
          </div>
          <div className={s.scrollerContent}>
						{[
							<>
								{appointment?.notes?.map(note => <AppointmentNote {...{note, appointmentId}}/>)}
								{!appointmentIsFetching ? '' : <Loader/>}
								<AddNoteCard {...{appointmentId}}/>
							</>, 
							<>						
								{appointment?.homework?.map(homework => <HomeworkCard {...{homework, appointmentId, appointment}}/>)}
								{!appointmentIsFetching ? '' : <Loader/>}
								<AddHomeworkCard {...{appointmentId}} />
							</>
						][mode]}
          </div>
        </div>}
        {(isMobile && !isConfVisible) ? null : <div className={s.conferenceCard}>
					<div className={s.conferenceHeader}>
					<div className={s.title}>
							Конференция
						</div>
						<div className={s.timer}>
							{isToday ?
								<>
									Начнется в {date.toLocaleString('ru-RU', {hour: '2-digit', minute: '2-digit'})}
								</> :
								<>
									{date.toLocaleDateString('ru-RU', {formatMatcher: 'basic'})}
								</>}
						</div>
					</div>
					{isConf ?
					<VideoConf/> :
					<>
						<div className={s.emptyText}>
							{shouldRequest ? 'Вы уже можете подключиться к конференции' : 'Конференция пока что не началась.'}
						</div>
						<div className={s.buttonWrapper}>
							<Button label="Подключиться" disabled={disabled} size="small" onClick={() => setIsConf(true)}/>
						</div>
					</>}
				</div>}
      </div>
			<Tooltip id="tooltip-has-new-homework-result" content="Есть непроверенное домашнее задание"/>
    </LiveKitRoom>
  );
};
