import s from './connect-student-modal.module.scss'
import { Modal } from 'shared/modal/modal';
import { Button } from 'shared/button/button';
import { useConnectStudent } from '../../../../hooks/useConnectStudent';
import { Input } from 'shared/input/input';
import { FormProvider, useForm } from 'react-hook-form';

export const ConnectStudentModal = ({isOpen, setOpen}) => {
	const { mutate } = useConnectStudent()

	const methods = useForm({
		mode: 'onTouched'
	})

	const { formState } = methods

  const handleSubmit = methods.handleSubmit(data => {
		const loginPayload = {
			email: data.email,
		}
	
		mutate(loginPayload)
		setOpen(null)
  })

	const email = methods?.watch('email')
	const notValid = (!formState.isValid && formState.isDirty) || !email

  return (
		<Modal {...{isOpen, setOpen}} title="Добавить студента" name={'connect-student-modal'}>
			<div className={s.card}>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit} className={s.form}>
							<Input
								placeholder='Email' 
								name='email' 
								className={s.input}
								validation={{
									required: {
										value: true,
										message: 'Заполните поле',
									},
									validate: {
										maxLength: (v) =>
											v.length <= 50 || "Почта должна содержать не более 50 символов.",
										matchPattern: (v) =>
											/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
											"Адрес электронной почты недействителен.",
									},
								}}/>
							<Button label='Добавить' className={s.btn} disabled={notValid}/>
						</form>
				</FormProvider>
			</div>
		</Modal>
  );
};