import React, { useCallback, useContext, useState } from 'react'
import s from './appointment-note.module.scss'
import { downloadFile } from '../../../helpers/downloadFile'
import { IconFile, IconPaperclip, IconPencil, IconTrash } from '@tabler/icons-react'
import { useUpdateAppointmentNote } from '../../../hooks/useUpdateAppointmentNote';
import { Button } from '../../shared/button/button';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { ModalsContext } from '../../layouts/dashboard-layout/dashboard';

const EditNote = ({note, onSuccess, appointmentId}) => {
	const [text, setText] = useState(note?.text)
	const [myFiles, setMyFiles] = useState(note?.attachments)

	const {mutate: editNote} = useUpdateAppointmentNote(appointmentId, onSuccess)

  const onDrop = useCallback(acceptedFiles => {
    setMyFiles([...myFiles, ...acceptedFiles])
  }, [myFiles])


	const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
		maxFiles: 5,
		onDrop,
	});

  const removeFiles = (name) => {
    setMyFiles(prev => prev.filter(el => el.name !== name))
  }

	const onClick = () => {
		editNote({appointmentId, noteId: note.id, 
			data: {
				text
			},
			attachments: acceptedFiles,
			filesId: myFiles?.map(el => el.id)
		})
	}
	const onCancel = () => {
		onSuccess()
	}

  return (
    <div className={s.addNoteCard}>
      <TextareaAutosize
        onChange={v => setText(v.target.value)}
        value={text}
				maxRows={5}
        placeholder="Начните печатать здесь...."
      ></TextareaAutosize>
			{myFiles.length >= 1 && 
				myFiles.map(el => (
					<div className={s.fileWrapper}>
						<p>
							<div className={s.icon}>
								<IconFile size={18}/>
							</div>
							{el.name} - {el.size}
						</p>
						<button className={s.removeBtn} onClick={() => removeFiles(el.name)}>
							<IconTrash size={18} color={'#F12828'}/>
						</button>
					</div>
				))}
			 <div className={s.jcsb}>
					<Button
						label="Отменить"
						size="small"
						theme={"secondary"}
						onClick={onCancel}
					/>
					<div className={s.btns}>
						<input {...getInputProps()} />
						<Button label={<IconPaperclip size={16}/>} {...getRootProps()} size="small" theme='secondary' className={s.attachmentButton}/>
						<Button label="Сохранить" size="small" onClick={onClick} />
					</div>
				</div>
    </div>
  );
};

export const AppointmentNote = ({note, appointmentId}) => {
	const [isEditable, setIsEditable] = useState(false)
	const { setModal } = useContext(ModalsContext)

	const isImage = (attachment) => {
		const type = attachment?.name.split('.').pop()
		return type === 'png' || type === 'jpeg' || type === 'jpg'
	}

	if(isEditable){
		return (
			<EditNote {...{note, appointmentId}} onSuccess={() => setIsEditable(false)}/>
		)
	}

	return (
		<div className={s.contentBlock}>
			<div className={s.text}>{note.text}</div>
			{note.attachments?.map(attachment => {
				if(isImage(attachment)){
					return <div className={s.img}>
						<img src={process.env.REACT_APP_STORAGE_URL + attachment.file} alt="" />
					</div>
				}else{
				return (
					<div className={s.fileWrapper}>
						<p onClick={() => downloadFile(process.env.REACT_APP_STORAGE_URL + attachment.file)}>
							<div className={s.icon}>
								<IconFile size={18}/>
							</div>
							{attachment.name}
						</p>
					</div>)
				}
			})}
			<div className={s.btns}>
				<button className={s.editBtn} onClick={() => setIsEditable(true)}><IconPencil/></button>
				<button className={s.removeBtn} onClick={() => setModal('remove-appointments-note', {appointmentId, noteId: note.id})}><IconTrash/></button>
			</div>
		</div>
	)
}