import { downloadDocApi } from "../api/api";

export const downloadFile = (url) => {
	downloadDocApi(url).then(data => {
		const blob = new Blob([data.data], { type: 'application/octet-stream' });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = url.split('/').pop()
		link.click();
		window.URL.revokeObjectURL(link.href);
	}).catch(error => console.error(error));
}