import { useMutation, useQueryClient } from "@tanstack/react-query"
import { classes } from "api/api"
import { toast } from "react-toastify"

export const useAddReview = () => {
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: (data) => classes.addReview(data.body, data.id),
    onSuccess: () => {
      queryClient.invalidateQueries(['history'])
			toast.success('Отзыв оставлен')
    },
  })

	return mutation
}