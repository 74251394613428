import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import { Input } from 'shared/input/input';
import { IconPlus, IconSearch, IconX } from '@tabler/icons-react';
import s from './search-teacher.module.scss'
import { Button } from 'shared/button/button';

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
				<Button label={<IconPlus size={16}/>} size="small" className={s.plusBtn}/>
      </components.DropdownIndicator>
    )
  );
};

const MultiValueRemove = props => {
  return (
    components.MultiValueRemove && (
      <components.MultiValueRemove {...props}>
				<IconX size={16}/> 
      </components.MultiValueRemove>
    )
  );
};

export const SearchTeacher = ({ onSubmit, control, instruments }) => {
	const instrumentsOptions = instruments?.map(i => ({label: i.name, options: i?.instruments?.map(ingroup => ({value: ingroup.id, label: ingroup.name}))}))

	return (
		<div className={s.wrapper}>
			<Input  
				placeholder="Поиск по преподавателям" 
				leftIcon={<IconSearch size={18} className={s.searchBtn} onClick={onSubmit}/>}
				name='q'
				className={s.input}
				onBlur={onSubmit}
				onKeyPress={(event) => {
					if (event.key === 'Enter') {
						event.preventDefault();
						onSubmit();
					}
				}}/>
			<Controller
				name="instruments"
				control={control}
				defaultValue={[]}
				render={({ field }) => (
					<Select 
						{...field} 
						options={instrumentsOptions} 
						isMulti 
						isSearchable={false}
						placeholder="Выберите инструменты"
						className={s.select}
						onChange={(value) => {
							field.onChange(value);
							onSubmit()
						}}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								outline: 'none',
								boxShadow: 'none',
								padding: 0,
								border: 'none',
								borderRadius: '.75rem',
								minHeight: 0
							}),
							valueContainer: (baseStyles) => ({
								...baseStyles,
								padding: 0,
							}),
							multiValue: (baseStyles) => ({
								...baseStyles,
								backgroundColor: '#f1f1f1',
								borderRadius: '.5rem',
								color: '#000',
								padding: '.5rem .35rem',
								"&:hover": {
									background: '#dcdcdc'
								}
							}),
							multiValueRemove: (baseStyles) => ({
								...baseStyles,
								cursor: 'pointer',
								"&:hover": {
									background: 'none'
								}
							}),
							menu: (baseStyles) => ({
								...baseStyles,
								borderRadius: 10,
								padding: 8,
								boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px'
							}),
							option: (baseStyles, {isSelected}) => ({
								...baseStyles,
								padding: '0.5rem 1rem',
								borderRadius: 4,
								color: '#4F5157',
								fontSize: '1rem',
								lineHeight: '1.5rem',
								cursor: 'pointer',
								background: isSelected ? '#f1f1f1' : 'none', 
								"&:hover": {
									background: '#f1f1f1'
								}
							}),
							multiValueLabel: (baseStyles) => ({
								...baseStyles,
								color: '#000',
								fontSize: '.875rem',
								fontWeight: '500',
								lineHeight: '100%',
							}),
						}}
						isClearable={false}
						components={{DropdownIndicator, MultiValueRemove, IndicatorSeparator: ''}}/>
				)}
			/>
		</div>
	);
};