import { MediaDeviceMenu, ParticipantPlaceholder, TrackToggle, usePersistentUserChoices, usePreviewTracks } from '@livekit/components-react';
import s from './check-conf-modal.module.scss'
import { Modal } from 'shared/modal/modal';
import { useEffect, useMemo, useRef, useState } from 'react';
import { facingModeFromLocalTrack, Track } from 'livekit-client';

const CheckView = () => {
	const {
    userChoices: initialUserChoices,
    saveAudioInputDeviceId,
    saveAudioInputEnabled,
    saveVideoInputDeviceId,
    saveVideoInputEnabled,
  } = usePersistentUserChoices();

  // Initialize device settings
  const [audioEnabled, setAudioEnabled] = useState(initialUserChoices.audioEnabled);
  const [videoEnabled, setVideoEnabled] = useState(initialUserChoices.videoEnabled);
  const [audioDeviceId, setAudioDeviceId] = useState(
    initialUserChoices.audioDeviceId,
  );
  const [videoDeviceId, setVideoDeviceId] = useState(
    initialUserChoices.videoDeviceId,
  );

  // Save user choices to persistent storage.
  useEffect(() => {
    saveAudioInputEnabled(audioEnabled);
  }, [audioEnabled, saveAudioInputEnabled]);
  useEffect(() => {
    saveVideoInputEnabled(videoEnabled);
  }, [videoEnabled, saveVideoInputEnabled]);
  useEffect(() => {
    saveAudioInputDeviceId(audioDeviceId);
  }, [audioDeviceId, saveAudioInputDeviceId]);
  useEffect(() => {
    saveVideoInputDeviceId(videoDeviceId);
  }, [videoDeviceId, saveVideoInputDeviceId]);

  const tracks = usePreviewTracks(
    {
      audio: audioEnabled ? { deviceId: initialUserChoices.audioDeviceId } : false,
      video: videoEnabled ? { deviceId: initialUserChoices.videoDeviceId } : false,
    },
  );

  const videoEl = useRef(null);

  const videoTrack = useMemo(
    () => tracks?.filter((track) => track.kind === Track.Kind.Video)[0],
    [tracks],
  );

  const facingMode = useMemo(() => {
    if (videoTrack) {
      const { facingMode } = facingModeFromLocalTrack(videoTrack);
      return facingMode;
    } else {
      return 'undefined';
    }
  }, [videoTrack]);

  const audioTrack = useMemo(
    () => tracks?.filter((track) => track.kind === Track.Kind.Audio)[0],
    [tracks],
  );

  useEffect(() => {
    if (videoEl.current && videoTrack) {
      videoTrack.unmute();
      videoTrack.attach(videoEl.current);
    }

    return () => {
      videoTrack?.detach();
    };
  }, [videoTrack]);

	return (
		<div className="lk-prejoin">
			<div className="lk-video-container">
				{videoTrack && (
					<video ref={videoEl} width="1280" height="720" data-lk-facing-mode={facingMode} />
				)}
				{(!videoTrack || !videoEnabled) && (
					<div className="lk-camera-off-note">
						<ParticipantPlaceholder />
					</div>
				)}
			</div>
			<div className="lk-button-group-container">
				<div className="lk-button-group">
					<TrackToggle
						initialState={audioEnabled}
						source={Track.Source.Microphone}
						onChange={(enabled) => setAudioEnabled(enabled)}
					>
						Микрофон
					</TrackToggle>
					<div className="lk-button-group-menu">
						<MediaDeviceMenu
							initialSelection={audioDeviceId}
							kind="audioinput"
							disabled={!audioTrack}
							tracks={{ audioinput: audioTrack }}
							onActiveDeviceChange={(_, id) => setAudioDeviceId(id)}
						/>
					</div>
				</div>
				<div className="lk-button-group">
					<TrackToggle
						initialState={videoEnabled}
						source={Track.Source.Camera}
						onChange={(enabled) => setVideoEnabled(enabled)}
					>
						Камера
					</TrackToggle>
					<div className="lk-button-group-menu">
						<MediaDeviceMenu
							initialSelection={videoDeviceId}
							kind="videoinput"
							disabled={!videoTrack}
							tracks={{ videoinput: videoTrack }}
							onActiveDeviceChange={(_, id) => setVideoDeviceId(id)}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export const CheckConfModal = ({isOpen, setOpen}) => {
  return (
		<Modal {...{isOpen, setOpen}} name={'check-conf-modal'}>
		{isOpen && <div className={s.card}>
				<CheckView/>
			</div>}
		</Modal>
  );
}