import React, { useContext } from 'react'
import s from './class-card.module.scss'
import classNames from 'classnames'
import { ModalsContext } from '../../layouts/dashboard-layout/dashboard';

export const ClassCard = ({student, teacher, payed, isUpcoming, isTeacher, starts_at, instrument, has_new_homework, has_unreviewed_homework, id}) => {
	const date = new Date(starts_at)
	const today = new Date()

	const {setModal} = useContext(ModalsContext)

	const isToday = date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()

	return (
		<div className={classNames(s.wrapper, isUpcoming ? s.upcoming : '')} onClick={() => setModal('appointment-modal', {appointmentId: id})}>
			{!payed ? <div className={s.badge}>Не оплачено</div> : ''}
			<div className={s.time}>
					{date.getHours() + ':' + (date.getMinutes() === 0 ? '00' :  date.getMinutes())}
			</div>
			<div className={s.content}>
				<span className={s.instrument} title={instrument.name}>{instrument.name}</span>
				{isTeacher ? 
				<div className={s.teacher}>
					<div className={s.avatar}>
						<img src={student?.avatar ? process.env.REACT_APP_STORAGE_URL + student?.avatar?.image : '/images/default_avatar.png'} alt="" />
					</div>
					<span>{student.name}</span>
				</div> :
				<div className={s.teacher}>
					<div className={s.avatar}>
						<img src={teacher?.avatar ? process.env.REACT_APP_STORAGE_URL + teacher?.avatar?.image : '/images/default_avatar.png'} alt="" />
					</div>
					<span>{teacher.name}</span>
				</div>
				}
			</div>
			{isTeacher ? (
				has_unreviewed_homework ? <span className={s.unread}></span> : ''
			) : (
				has_new_homework ? <span className={s.unread}></span> : ''
			)}
		</div>
	)
}
