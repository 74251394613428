import React, { useContext } from 'react'
import s from './history-class-card.module.scss'
import classNames from 'classnames'
import { ModalsContext } from '../../layouts/dashboard-layout/dashboard'

export const HistoryClassCard = ({ starts_at, price, instrument, student, role, id, teacher, status, feedback_rating, feedback_message}) => {
	const date = new Date(starts_at)

	const { setModal } = useContext(ModalsContext)
	
	const months = [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
  ]

	return (
		<tr className={s.card} key={'history-class-' + id}>
			<td data-label={'Статус'}>
				<div className={s.td}>
					{{
						"passed": <span className={classNames(s.status, s.success)}>Завершен</span>,
						"cancelled": <span className={classNames(s.status, s.cancel)}>Отменен</span>,
					}[status]}
				</div>
			</td>
			<td data-label={'Инструмент'}>
				<div className={s.td}>
					<div className={s.instrument}>
					{instrument.name}
					</div>
				</div>
			</td>
			<td data-label={role === 'student' ? 'Преподаватель' : 'Студент'}>
				<div className={s.td}>
					<div className={s.teacher}>
						<div className={s.avatar}>
							<img src={(role === 'student' ? teacher?.avatar : student?.avatar) || '/images/default_avatar.png'} alt="" />
						</div>
						<span>{role === 'student' ? teacher?.name : student?.name}</span>
					</div>
				</div>
			</td>
			<td data-label={'Время'}>
				<div className={s.td}>
					<div className={s.duration}>
						50 мин.
					</div>
				</div>
			</td>
			<td data-label={'Ваша оценка'}>
				<div className={s.td}>
					<button className={s.ratingButton} onClick={() => 
						role === 'student' ? setModal('add-review-modal', {appointmentId: id}) : 
						setModal('view-review-modal', {review: {feedback_rating, feedback_message}})}>
						{feedback_rating !== null  && <div className={s.ratingNumber}>
							{feedback_rating}
						</div>}
						{role === 'student' ?
							feedback_rating ? 'Изменить' : 'Оставить отзыв' :
							feedback_rating ? 'Прочитать' : ''
						}						
					</button>
				</div>
			</td>
			<td data-label={'Стоимость'}>
				<div className={s.td}>
					<div className={s.price}>
						{price + '₽'}
					</div>
				</div>
			</td>
			<td data-label={'Дата занятия'}>
				<div className={s.td}>
					<div className={s.date}>
						{date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()}
					</div>
				</div>
			</td>
		</tr>
	)
}