import React from 'react'
import s from './reviews.module.scss'
import { IconStar, IconStarFilled } from '@tabler/icons-react'
import Rating from 'react-rating'
import { getNoun } from 'helpers/getNoun';
import { Review } from './review';
import { useScrollWithShadow } from 'hooks/useScrollWithShadow';
import classNames from 'classnames';

export const Reviews = ({reviews, rating}) => {
	const lineWidth = (number) => {
		return (reviews?.filter(e => +e.feedback_rating === +number).length/reviews?.length)*100
	}
	const { boxShadow, onScrollHandler } = useScrollWithShadow();

	return (
		<div className={s.wrapper}>
			<div className={s.ratingWrapper}>
				<div>
					<div className={s.ratingNumber}>
						{rating}
					</div>
					<Rating className={s.ratingStars} fullSymbol={<IconStarFilled/>} emptySymbol={<IconStar/>} readonly={true} initialRating={rating}/>
					<div className={s.reviewsCount}>
						{reviews?.length + ' '}
						{getNoun(reviews?.length, 'отзыв', 'отзыва', 'отзывов')}
					</div>
				</div>
				<ul className={s.ratingLines}>
					<li>
						<div className={s.ratingLineNumber}>5</div>
						<div className={s.ratingLine}>
							<span style={{width: lineWidth(5) + '%'}}></span>
						</div>
					</li>
					<li>
						<div className={s.ratingLineNumber}>4</div>
						<div className={s.ratingLine}>
							<span style={{width: lineWidth(4) + '%'}}></span>
						</div>
					</li>
					<li>
						<div className={s.ratingLineNumber}>3</div>
						<div className={s.ratingLine}>
							<span style={{width: lineWidth(3) + '%'}}></span>
						</div>
					</li>
					<li>
						<div className={s.ratingLineNumber}>2</div>
						<div className={s.ratingLine}>
							<span style={{width: lineWidth(2) + '%'}}></span>
						</div>
					</li>
					<li>
						<div className={s.ratingLineNumber}>1</div>
						<div className={s.ratingLine}>
							<span style={{width: lineWidth(1) + '%'}}></span>
						</div>
					</li>
				</ul>
			</div>
			<div className={classNames(s.reviewsWrapper, boxShadow?.includes('top') ? s.withTop : '', boxShadow?.includes('bottom') ? s.withBottom : '')}>
				<div className={s.reviewsScroller} onScroll={onScrollHandler}>
					{reviews.map(r => <Review review={r}/>)}
				</div>
			</div>
		</div>
	)
}
