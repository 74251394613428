import React from 'react'
import s from './profile-info-card.module.scss'
import { NavLink } from 'react-router-dom'
import { IconCopy, IconEdit } from '@tabler/icons-react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'

export const ProfileInfoCard = ({profile, analytics}) => {
	return (
		<div className={s.card}>
			<div className={s.profile}>
				<div className={s.avatar}>
					<img src={profile.avatar ? process.env.REACT_APP_STORAGE_URL + profile.avatar.image : '/images/default_avatar.png'} alt="" />
				</div>
				<div className={s.profileContent}>
					<div className={s.name}>{profile?.name} <NavLink className={s.edit} to={'/settings'}><IconEdit size={18}/></NavLink></div>
					<div className={s.mail}>{profile?.email}</div>
				</div>
			</div>
			<div className={s.analytics}>
				{analytics?.map((el, index) => (
					<div className={s.infoBlock} key={'analytics-' + index}>
						<div className={s.infoLabel}>
							{el.label}
						</div>
						<div className={s.infoValue}>
							{el.value}
						</div>
					</div>
				))}
			</div>		
		</div>
	)
}
