import { useMutation, useQueryClient } from "@tanstack/react-query"
import { profile } from "api/api"
import { toast } from "react-toastify"

export const useUpdateTimeSlots = () => {
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: (data) => profile.updateTimeSlots(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['time-slots'])
			toast.success('Данные обновлены')
    },
  })

	return mutation
}

// {
// 	"timeslots": [
// 			{
// 					"weekday": "monday",
// 					"time": "09:00"
// 			}
// 	]
// }