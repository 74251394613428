import s from './remove-homework.module.scss'
import { Modal } from 'shared/modal/modal';
import { Button } from 'shared/button/button';
import { useRemoveHomework } from '../../../../hooks/useRemoveHomework';

export const RemoveHomeworkModal = ({isOpen, setOpen, modalParams}) => {
	const appointmentId = modalParams?.appointmentId
	const homeworkId = modalParams?.homeworkId
	const {mutate: remove} = useRemoveHomework(appointmentId)
	
	const removeNote = () => {
		remove({appointmentId, homeworkId})
		setOpen()
	}

  return (
		<Modal {...{isOpen, setOpen}} name={'remove-homework'}>
			<div className={s.card}>
					<div className={s.title}>Вы уверены?</div>
					<div className={s.text}>Вы точно хотете удалить домашнее задание?</div>
					<div className={s.btns}>
						<Button
							theme='secondary'
							className={s.btn}
							onClick={() => setOpen()}
							label={'Нет'}
						/>
						<Button
							className={s.btn}
							onClick={removeNote}
							label={'Да'}
						/>
					</div>
			</div>
		</Modal>
  );
};