import React from 'react'
import s from './teacher-card.module.scss'
import { getNoun } from 'helpers/getNoun'
import { IconCheck } from '@tabler/icons-react'

export const TeacherCard = ({id, teacher_bio, avatar, grade, teacher_education, appointments_count, lname, fname, openModal, rating, small, name, set_selectedTeacher, selectedTeacher, instruments}) => {
	if(small){
		return(	
		<div className={s.smallCard}>
			<div className={s.baseInfo}>
				<div className={s.avatar} onClick={() => {
					if(set_selectedTeacher){
						set_selectedTeacher(id)
					}
					openModal('teacher-modal', {teacherId: id})
				}}>
					<img src={avatar ? process.env.REACT_APP_STORAGE_URL + avatar.image : '/images/default_avatar.png'} alt="" />
				</div>
				<div>
					<h5 className={s.name} onClick={() => {
						if(set_selectedTeacher){
							set_selectedTeacher(id)
						}
						openModal('teacher-modal', {teacherId: id})
					}}>
						{`${fname || ""} ${name || ""} ${lname || ""}`}
					</h5>
					<p className={s.instruments}>
						{instruments?.map(i => 
							<span className={s.instrumentTab}>{i.name}</span>)}
					</p>
					<div className={s.infos}>
						<p>🌟 {rating}</p>
						<p>📖 {appointments_count}{getNoun(appointments_count, " занятие", " занятия", " занятий")}</p>
						{/* <p>💬 142 отзывов</p> */}
					</div>
				</div>
			</div>
			<div className={s.price}>
				{grade?.price ? grade?.price + ' ₽/час' : 'Цена договорная'}
			</div>
		</div>)
	}
	
	return (
		<div className={`${s.card} ${selectedTeacher == id && s.active}`} onClick={() => set_selectedTeacher(id)}>
			<div className={s.baseInfo}>
				<div className={s.avatar}>
					<img src={avatar ? process.env.REACT_APP_STORAGE_URL + avatar.image : '/images/default_avatar.png'} alt="" />
				</div>
				<div>
					<h5 className={s.name}>
						{`${fname || ""} ${name || ""} ${lname || ""}`}
					</h5>
					<p className={s.onlineStatus}>
						Был в сети 49 минут назад
					</p>
					<div className={s.infos}>
						<p>🌟 {rating}</p>
						{/* <p>📖 38 занятий</p>
						<p>💬 142 отзывов</p> */}
					</div>
				</div>
			</div>
			<div className={s.moreInfo}>
				<div className={s.desc}>
					<span className={s.descLabel}>О себе</span>
					<span className={s.descContent}>{teacher_bio}</span>
				</div>
				<div className={s.education}>
					<span className={s.educationLabel}>Образование и опыт</span>
					<span className={s.educationContent}>
						{teacher_education?.map((e) => (
							<span className={s.educationContent}>
								<IconCheck className={s.icon} />
								{e}
							</span>
						))}
					</span>
				</div>
			</div>
		</div>
	)
}
