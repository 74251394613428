import { TrackLoop } from '@livekit/components-react';
import * as React from 'react';
import s from './video-conf.module.scss'

export function Layout({ tracks, ...props }) {
  const gridEl = React.createRef();

  return (
    <div ref={gridEl} className={s.layout} {...props}>
      <TrackLoop tracks={tracks}>{props.children}</TrackLoop>
    </div>
  );
}
