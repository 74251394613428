import React, { useEffect, useMemo, useRef } from 'react';
import s from './classes-board.module.scss';
import { ClassCard } from '../class-card/class-card';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useTimeSlots } from 'hooks/useTimeSlots';
import { useGroupedTimeSlots } from 'hooks/useGroupedTimeSlots';
import { Loader } from 'shared/loader/loader';
import { Button } from '../../shared/button/button';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useMedia } from 'use-media';
import months from "../../../data/months.json";
import { useUpcomingAppointments } from '../../../hooks/useUpcomingAppointments';
import { addDays, endOfWeek } from 'date-fns';

const getMonthAndDate = (d) => {
	const month = d.getMonth()
	const date = d.getDate()

	return [months.find((el) => el.number === month).genitive.toLowerCase(), date]
}

export const ClassesBoard = ({ appointments, appointmentsIsFetched, isTeacher, setModal, start_date, end_date , set_end_date, set_start_date, className}) => {
		const {data: timeSlots} = useTimeSlots()
		const groupedTimeSlots = useGroupedTimeSlots(timeSlots)
		const isMobile = useMedia({ maxWidtdh: "768px" });
		const todayRef = useRef(null);
		const {data: upcoming} = useUpcomingAppointments()

		const today = new Date()
		today.setHours(0,0,0,0)

		const plusHour = (time) => {
			return (+time.split(':')[0] + 1) + ':' + time.split(':')[1]
		}

		const goToPreviousWeek = () => {
			const previousWeekStart = addDays(start_date, -7);
			const previousWeekEnd = endOfWeek(previousWeekStart);

				set_start_date(previousWeekStart);
				set_end_date(previousWeekEnd);
		};

		// Функция для перехода к следующей неделе
		const goToNextWeek = () => {
				const nextWeekStart = addDays(start_date, 7);
				const nextWeekEnd = endOfWeek(nextWeekStart);
				
				set_start_date(nextWeekStart);
				set_end_date(nextWeekEnd);
		};

    const weekClasses = useMemo(() => {
				let startDate = new Date(start_date);
				startDate.setHours(0, 0, 0, 0);
				const endDate = new Date(end_date);
				endDate.setHours(0, 0, 0, 0);
		
				let classesByDay = {};
				while (startDate <= endDate) {
						let dayName = startDate.toLocaleString('ru-RU', { weekday: 'long' });
						classesByDay[dayName] = appointments?.filter(classItem => {
								const classDate = new Date(classItem.starts_at);
								return classDate.toLocaleString('ru-RU', {
										year: "numeric",
										month: "long",
										day: "numeric",
								}) === startDate.toLocaleString('ru-RU', {
										year: "numeric",
										month: "long",
										day: "numeric",
								});
						});
						startDate.setDate(startDate.getDate() + 1);
				}
				return classesByDay;
		}, [appointments, start_date, end_date]);

		const fullDays = useMemo(() => {
			let startDate = new Date(start_date);
			startDate.setHours(0, 0, 0, 0);
			const endDate = new Date(end_date);
			endDate.setHours(0, 0, 0, 0);
	
			let days = {};
			while (startDate <= endDate) {
					let dayName = startDate.toLocaleString('ru-RU', { weekday: 'long' }).toLowerCase();
					days[dayName] = new Date(startDate); // Сохраняем объект даты для каждого дня
					startDate.setDate(startDate.getDate() + 1);
			}
			return days;
	}, [start_date, end_date]);

		useEffect(() => {
			if (todayRef?.current) {
				todayRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
			}
		}, [todayRef, appointmentsIsFetched]);

    return (
        <div className={classNames(s.weekCard, className)}>
					{groupedTimeSlots ? 
						<>
							<div className={s.weekHeader}>
									{(start_date && end_date) && <div className={s.selectWrapper}>
										<Button
											onClick={() => goToPreviousWeek()}
											label={<IconChevronLeft size={10} />}
											theme="secondary"
											className={s.monthBtn}
										/>
										<span>
											{`${getMonthAndDate(start_date)[1]} ${getMonthAndDate(start_date)[0]} - ${getMonthAndDate(end_date)[1]} ${getMonthAndDate(end_date)[0]}`}
										</span>
										<Button
											onClick={() => goToNextWeek()}
											label={<IconChevronRight size={10} />}
											theme="secondary"
											className={s.monthBtn}
										/>
									</div>}
									{isTeacher ? <NavLink className={s.weekHeaderLink} onClick={() => setModal('edit-schedule-modal')}>Изменить расписание</NavLink> : ''}
							</div>
							<div className={s.hLine}></div>
							{appointmentsIsFetched ? <div className={s.weekWrapper}>
									{fullDays && Object?.entries(fullDays)?.map(([dayName, date]) => {
											const weekday = date.toLocaleString('en-us', { weekday: "long" }).toLowerCase();
											const isNonWorking = !groupedTimeSlots[weekday]
											
											const isToday = date.toISOString().slice(0, 10) === today.toISOString().slice(0, 10)

											return (
													<div key={dayName} ref={isToday ? todayRef : null}  className={classNames(s.weekDay, (isNonWorking && isTeacher) ? s.nonworking : '')}>
															<div className={s.weekDayHeader}>
																	<div className={classNames(s.weekDayTitle, isToday ? s.today : '')}>
																			{date.toLocaleString('ru-RU', {
																					day: "numeric",
																					month: "long",
																			})}
																			<span>{' ' + dayName}</span>
																	</div>
																	{isTeacher ? <div className={s.weekDayTimeRange}>
																			{isNonWorking ? 'Выходной' :
																					('Рабочее время ' + (groupedTimeSlots[weekday]?.from) + '-' + plusHour(groupedTimeSlots[weekday]?.to))}
																	</div> : ''}
															</div>
															<div className={s.hLine}></div>
															<div className={s.weekDayClasses}>
																	{weekClasses[dayName] && weekClasses[dayName].map(cl => <ClassCard isTeacher={isTeacher} isUpcoming={upcoming?.id === cl?.id} {...cl} />)}
															</div>
													</div>
											);
									})}
							</div>  : <Loader/>}
					</> : <Loader/>
				}
        </div>
    );
};
