import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PostPayment } from '../../../components/profile/post-payment/post-payment'
import { Loader } from 'shared/loader/loader';
import { useAppointment } from 'hooks/useAppointment';

export const PostPaymentPage = () => {
	const {appointmentId} = useParams()
	const { data: appointment, isLoading } = useAppointment(appointmentId)

  if (isLoading) return <Loader/>;
	
	return <PostPayment appointment={appointment}/>
}
