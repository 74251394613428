import React, { useEffect, useState } from 'react'
import s from './edit-schedule-modal.module.scss'
import { Modal } from 'shared/modal/modal'
import {  IconSquare, IconSquareFilled } from '@tabler/icons-react'
import classNames from 'classnames'
import { Button } from 'shared/button/button';
import { useUpdateTimeSlots } from 'hooks/useUpdateTimeSlots';
import { useTimeSlots } from 'hooks/useTimeSlots'

const weekDays = [
	{en: 'monday', ru: 'Пн'},
	{en: 'tuesday', ru: 'Вт'},
	{en: 'wednesday', ru: 'Ср'},
	{en: 'thursday', ru: 'Чт'},
	{en: 'friday', ru: 'Пт'},
	{en: 'saturday', ru: 'Сб'},
	{en: 'sunday', ru: 'Вс'}
]

export const EditScheduleModal = ({setOpen, isOpen}) => {
	const { data: timeSlots } = useTimeSlots()
	const { mutate: updateTimeSlots } = useUpdateTimeSlots()
	
	const [checkedDays, setCheckedDays] = useState(timeSlots || [])
	
	useEffect(() => {
		if(timeSlots) setCheckedDays(timeSlots)
	}, [timeSlots])

	const onSubmit = () => {
		updateTimeSlots({
			timeslots: checkedDays
		})
		setOpen()
	}

	const fullDay = (d) => {
		const allDaysAreChecked = [...Array(18)].every((e, i) => {
			const timeString = (i < 4 ? '0' : '') + (i + 6) + ':00';
			return checkedDays.some(el => el.weekday === d.en && el.time === timeString);
		});

		if(allDaysAreChecked){
			[...Array(18)].forEach((e, i) => {
				setCheckedDays(prev => prev.filter(el => el.weekday !== d.en));
			})
		}else{
			[...Array(18)].forEach((e, i) => {
				const timeString = (i < 4 ? '0' : '') + (i + 6) + ':00';
				setCheckedDays(prev => [...prev, { weekday: d.en, time: timeString }]);
			})
		}
		
	}

	const fullTime = (timeString) => {
		const allDaysAreChecked = weekDays.every(d => {
			return checkedDays.some(el => el.weekday === d.en && el.time === timeString);
		});

		if(allDaysAreChecked){
			weekDays.forEach((d) => {
				setCheckedDays(prev => prev.filter((el) => el.time !== timeString));
			})
		}else{
			weekDays.forEach((d) => {
				setCheckedDays(prev => [...prev, { weekday: d.en, time: timeString }]);
			})
		}
	}

	return (
		<Modal {...{isOpen, setOpen}} name={'edit-schedule-modal'} title="Редактирование расписания">
			<div className={s.card}>
				<table>
						<tr>
								<th></th>
								{weekDays.map((d, dayIndex) =>
										<th key={'day-header-' + dayIndex}>
											<button className={s.fullDayBtn} onClick={() => fullDay(d)}>{d.ru}</button>
										</th>
								)}
						</tr>
						{[...Array(18)].map((x, i) => {
								const timeString = (i < 4 ? '0' : '') + (i + 6) + ':00';
								const timeEndString = (i < 4 ? '0' : '') + (i + 6) + ':50';
								return (
										<tr key={'time-row-' + i}>
												<td>
													<button className={s.fullTimeBtn} onClick={() => fullTime(timeString)}>
														{timeString} - {timeEndString}
													</button>
												</td>
												{weekDays.map((d, dayIndex) => {
														const checkedIndex = checkedDays.findIndex(el => el.weekday === d.en && el.time === timeString);
														return (
																<td key={'day-and-time-cell-' + dayIndex}>
																		<button onClick={() => {
																				if (checkedIndex !== -1) {
																						setCheckedDays(prev => prev.filter((el, index) => index !== checkedIndex));
																				} else {
																						setCheckedDays(prev => [...prev, { weekday: d.en, time: timeString }]);
																				}
																		}} className={classNames(s.dayBtn, checkedIndex !== -1 ? s.active : '')}>
																				{checkedIndex !== -1 ? <IconSquareFilled /> : <IconSquare />}
																		</button>
																</td>
														);
												})}
										</tr>
								);
						})}
				</table>
				<div className={s.btns}>
					<Button label={'Отмена'} theme='secondary' onClick={() => setOpen()}/>
					<Button label={'Сохранить'} onClick={onSubmit}/>
				</div>
			</div>
		</Modal>
	)
}
