import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { auth } from 'api/api';
import s from './VerifyPage.module.scss'
import ReactConfetti from 'react-confetti';
import { useWindowSize } from 'hooks/useWindowSize';
import { useMedia } from 'use-media';

export const VerifyPage = () => {
	let { token } = useParams();
	const [verified, setVerified] = useState(false)
	const [isError, setIsError] = useState(false)
	const { width, height } = useWindowSize()

	const isTablet = useMedia({maxWidth: '1312px'})

	
	useEffect(() => {
		auth.verifyEmail(token).then(res => {
			if(res.status == 200 || res.status == 204){
				setVerified(true)
			}
		}).catch(err => {
			setVerified(false)
			setIsError(true)
		});
	}, [])

	return (
		<div className={s.wrapper}>
			{verified ? 
			isError ? <div className={s.wait}>Ссылка невалидна</div> :
				<>
					<div className={s.successWrapper}>
						<img src="/images/undraw_certification_re_ifll.svg" alt="" />
						<h2>Почта успешно подтверждена. </h2>
						<p>А пока...</p>
						<NavLink to="/">Вернуться на главную</NavLink>
					</div>
					<ReactConfetti height={isTablet ? height - 63 : height} width={width} recycle={false} numberOfPieces={2000} />
				</>
			:
			<div className={s.wait}>Ожидаем ответа...</div>
			}
		</div>
	)
}