import React from 'react'
import s from './reviews.module.scss'
import { IconStar, IconStarFilled } from '@tabler/icons-react'
import Rating from 'react-rating'
import { TextCollapse } from '../text-collapse/text-collapse'

export const Review = ({review}) => {
	return (
		<div className={s.reviewCard}>
			<div className={s.flex}>
				<div className={s.avatar}>
					<img
						src={
							review?.user?.avatar
								? process.env.REACT_APP_STORAGE_URL + review?.user?.avatar
								: "/images/default_avatar.png"
						}
						alt=""
					/>
				</div>
				<div className={s.content}>
					<span className={s.name}>
						{review?.user?.name}
					</span>
					<Rating className={s.reviewStars} fullSymbol={<IconStarFilled size={18} className={s.star}/>} emptySymbol={<IconStar size={18} className={s.star}/>} readonly={true} initialRating={review.feedback_rating}/>
				</div>
				<TextCollapse className={s.reviewText} maxHeight={46.8}>{review?.feedback_message}</TextCollapse>
			</div>
		</div>
	)
}
