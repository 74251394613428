import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { auth } from 'api/api'

export const VkRedirect = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [error, setError] = useState(null)

  const login = (data) => {
		const loginPayload = {
			token: data.token,
			uuid: data.uuid
		}
	
		auth.loginVk(loginPayload).catch((response) => {
			setError(response.response)
		})
  }

	useEffect(() => {
		const data = JSON.parse(searchParams.get('payload'));
		if(data){
			login(data)
		}
	}, [])

	return (
		<div>
		</div>
	)
}
