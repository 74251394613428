import React, { forwardRef, useRef, useState } from 'react'
import s from "./calendar-input.module.scss"
import ReactDatePicker, { CalendarContainer } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { IconCalendar } from '@tabler/icons-react';
import { CalendarHeader } from './calendar-input-header';

export const CalendarInput = ({placeholder, className, label, onChange, value}) => {
	const [date, setDate] = useState(new Date(value))
	const calendar = useRef(null)

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div className={s.inputsWrapper} onClick={onClick} ref={ref}>
			<input value={value} placeholder={placeholder}/>
			<IconCalendar size={20} color='#919396'/>
		</div>
  ));

	const MyContainer = ({ className, children }) => {
    return (
			<CalendarContainer className={className + ' ' + s.wrapper}>
				<div style={{ position: "relative" }}>{children}</div>
			</CalendarContainer>
    );
  };

	return (
		<div className={`${className} ${s.calendarGroup}`}>
			{label && <div className={s.label}>
				<span>{label}</span>
			</div>}
			 <ReactDatePicker
					selected={date}
					ref={calendar}
					onChange={(update) => {
						onChange(update);
						setDate(new Date(update))
					}}
					customInput={<ExampleCustomInput />}
					calendarClassName={s.wrapper}
					renderCustomHeader={CalendarHeader}
					calendarContainer={MyContainer}
					minDate={new Date(1900, 0, 1)}
				/>
		</div>
	)
}
