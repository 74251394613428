import React, { useEffect, useState } from 'react';
import s from './choose-date.module.scss';
import { getDaysInMonth } from 'helpers/getDaysInMonth';
import months from '../../../data/months.json';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { Button } from 'shared/button/button';
import { Calendar } from 'shared/calendar/calendar';
import classNames from 'classnames';
import { useGroupedTimeSlots } from 'hooks/useGroupedTimeSlots';
import { Input } from 'shared/input/input';
import { FormProvider, useForm } from 'react-hook-form';

export const ChooseDate = ({ teacher, withTitle, selectedDate, createClass, set_selectedDate, selectedTime, set_selectedTime, selectedInstrument, set_selectedInstrument }) => {
  const date = new Date();
  const [month, set_month] = useState(date.getMonth());
  const [year, set_year] = useState(date.getFullYear());
  const [weekday, set_weekday] = useState('');
  const [calendar, set_calendar] = useState(getDaysInMonth(month, year));

	const methods = useForm()
	const {control, handleSubmit} = methods

  useEffect(() => {
    set_calendar(getDaysInMonth(month, year));
  }, [month]);

	useEffect(() => {
		const d = new Date(selectedDate?.split('.').reverse().join('-'))
		set_weekday(d.toLocaleString('en-US', { weekday: 'long' }).toLowerCase());
	}, [selectedDate]) 

	const nextMonth = () => {
		if(month >= 11){
			set_month(0)
			set_year(prev => prev + 1)
		}else{
			set_month(prev => prev + 1)
		}
	}
	
	const prevMonth = () => {
		if(month <= 0){
			set_month(11)
			set_year(prev => prev - 1)
		}else{
			set_month(prev => prev - 1)
		}
	}

	const getTime = (d) => {
		return ((d.getHours() + '').length ===  2 ? d.getHours() : '0' + d.getHours()) + ':' + ((d.getMinutes() + '').length ===  2 ? d.getMinutes() : '0' + d.getMinutes());
	}

	const isDisabledTime = (el) => {
		return teacher.appointments?.findIndex(i => {
			const d = new Date(i.starts_at);
			const t = getTime(d)
			const dstring = d.toLocaleString('ru-RU', { day: "numeric", month: "numeric", year: "numeric" });
			return el === t && dstring === selectedDate;
		}) !== -1;
	}

  const getNumberYearMonth = (year, month) => year *  100 + month;
	const groupedTimeSlots = useGroupedTimeSlots(teacher?.timeslots)


  const onSubmit = handleSubmit(data => {
		createClass()
  })
	
  return (
    <div className={s.wrapper}>
      {withTitle && (
        <>
          <h2>Выбор даты первого занятия</h2>
          <p className={s.subtitle}>Перед началом своего обучения,<br/>давай немного познакомимся</p>
        </>
      )}
      <div className={s.flex}>
        <div className={s.selectInstrument}>
          <p className={s.instrumentsTitle}>Инструмент</p>
          <div className={s.instruments}>
            {teacher?.instruments?.map(el => (
              <span className={classNames(selectedInstrument === el.id && s.active)} onClick={() => set_selectedInstrument(el.id)}>{el.name}</span>
            ))}
          </div>
        </div>
        <div className={classNames(s.calendarWrapper, !selectedInstrument && s.disabled)}>
          <div className={s.selectWrapper}>
            {(+month > +date.getMonth() || +date.getFullYear() < year) ? <Button onClick={() => selectedInstrument && prevMonth()} label={<IconChevronLeft size={20}/>} theme='secondary' className={s.monthBtn}/> : null}
            <span>{months.find(el => el.number === month).name.toLowerCase()}</span>
            <span className={s.yearSpan}>{year}</span>
            {getNumberYearMonth(year, month) < getNumberYearMonth(date.getFullYear(), date.getMonth() +  1) ? <Button onClick={() => selectedInstrument && nextMonth()} label={<IconChevronRight size={20}/>} theme='secondary' className={s.monthBtn}/> : null}
          </div>
          <Calendar month={calendar} teacher={teacher} selectedMonth={month} selectedYear={year} {...{selectedDate, set_selectedDate, selectedInstrument}}/>
        </div>
        {groupedTimeSlots[weekday] && (
          <>
            <div className={s.timesheetWrapper}>
              {groupedTimeSlots[weekday]?.times?.findIndex(el => +el?.split(':')[0] <=  12) !== -1 && <>
								<p className={s.timesheetTitle}>Утро</p>
								<div className={s.timesheet}>
									{groupedTimeSlots[weekday]?.times?.map(el => {
										if (+el?.split(':')[0] > 12) return null;
										const isDisabled = isDisabledTime(el)
										return (
											<span className={classNames(selectedTime === el && s.active, (isDisabled) && s.disabled)} onClick={() => !isDisabled && set_selectedTime(el)}>{el} - {el?.split(':')[0] + ':50'}</span>
										);
									})}
								</div>
							</>}
             {groupedTimeSlots[weekday]?.times?.findIndex(el => +el?.split(':')[0] <= 18 && +el?.split(':')[0] >= 13) !== -1 && 
						 <>
						  <p className={s.timesheetTitle}>День</p>
              <div className={s.timesheet}>
                {groupedTimeSlots[weekday]?.times?.map(el => {
                  if(+el?.split(':')[0] > 18 || +el?.split(':')[0] < 13) return null;
									const isDisabled = isDisabledTime(el)
                  return (
                    <span className={classNames(selectedTime === el && s.active, (isDisabled) && s.disabled)} onClick={() => !isDisabled && set_selectedTime(el)}>{el} - {el?.split(':')[0] + ':50'}</span>
                  );
                })}
              </div>
						 </>}
						 {groupedTimeSlots[weekday]?.times?.findIndex(el => +el?.split(':')[0] <= 24 && +el?.split(':')[0] >= 19) !== -1 && <>
              <p className={s.timesheetTitle}>Вечер</p>
              <div className={s.timesheet}>
                {groupedTimeSlots[weekday]?.times?.map(el => {
                  if(+el?.split(':')[0] > 24 || +el?.split(':')[0] < 19) return null;
									const isDisabled = isDisabledTime(el)
                  return (
                    <span className={classNames(selectedTime === el && s.active, (isDisabled) && s.disabled)} onClick={() => !isDisabled && set_selectedTime(el)}>{el} - {el?.split(':')[0] + ':50'}</span>
                  );
                })}
              </div>
						 </>}
            </div>
          </>
        )}
        {(selectedDate && selectedInstrument) && 
					<Button 
						label='Перейти к оплате' 
						onClick={onSubmit} 
						className={s.btn}
						disabled={!selectedTime || !selectedDate || !teacher || !selectedInstrument}/>}
      </div>
    </div>
  );
};