import s from './update-avatar-modal.module.scss'
import { Modal } from 'shared/modal/modal';
import { useRef, useState } from 'react';
import Avatar from 'react-avatar-edit';
import { Button } from 'shared/button/button';
import { b64toBlob } from 'helpers/b64toBlob';
import { useUpdateProfileAvatar } from 'hooks/useUpdateProfileAvatar';

export const UpdateAvatarModal = ({isOpen, setOpen}) => {
	const [img, setImg] = useState(null)
	const avatarRef = useRef()

	const { mutate: updateProfileAvatar } = useUpdateProfileAvatar()

	const onCrop = (view) => {
		setImg(view)
	}

	const onClose = () => {
		setImg(null)
	}

	const onSave = () => {
		if(img){
			const b64url = img
			const splitURL = b64url.split(",");
			const contentType = splitURL[0].split(":")[1].split(";")[0];
			const b64Data = splitURL[1];
			const blob = b64toBlob(b64Data, contentType);
			updateProfileAvatar(blob)
			setOpen(null)
		}
	};

  return (
		<Modal {...{isOpen, setOpen}} name={'update-avatar-modal'}>
			<div className={s.card}>
				<Avatar exportAsSquare label='Выберете файл' width={300} height={300} ref={avatarRef} onCrop={onCrop} onClose={onClose}/>
				<Button onClick={() => onSave()} label={'Сохранить'} className={s.btn} disabled={!img}/>
			</div>
		</Modal>
  );
};