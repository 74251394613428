import { IconFile } from "@tabler/icons-react";
import { downloadFile } from "../../../helpers/downloadFile";
import { Button } from "../../shared/button/button";
import { CommentCard } from "../../shared/homework-comment-card/homework-comment-card";
import TextareaAutosize from 'react-textarea-autosize';
import { isImage } from "../../../helpers/isImage";
import classNames from "classnames";
import { useApproveHomework } from "../../../hooks/useApproveHomework";
import { useRejectHomework } from "../../../hooks/useRejectHomework";
import { useState } from "react";
import s from './homework-submission-card.module.scss'
import { getFullName } from "../../../helpers/getFullname";

export const SubmissionCard = ({ submission, appointment, homework }) => {
  const [isCommentShown, setIsCommentShown] = useState(false);
  const [text, setText] = useState("");

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  const { mutate: approve } = useApproveHomework(appointment?.id);
  const { mutate: reject } = useRejectHomework(appointment?.id, () => {
		setIsCommentShown(false)
	});

  const submitData = {
    appointmentId: appointment.id,
    homeworkId: homework.id,
    submissionId: submission.id,
  };

  return (
    <>
      <div className={s.contentBlock}>
        <div className={s.submissionHeader}>
          <div className={s.jcsb}>
            <span className={s.date}>{formatDate(submission.created_at)}</span>
            {isCommentShown ? null : submission.status !== "created" ? (
              <span className={classNames(s.status, s[submission.status])}>
                {
                  {
                    rejected: "Отклонено",
                    approved: "Принято",
                  }[submission.status]
                }
              </span>
            ) : (
              <div className={s.btns}>
                <button
                  className={s.decline}
                  onClick={() => setIsCommentShown(true)}
                >
                  Отклонить
                </button>
                <button
                  className={s.approve}
                  onClick={() => approve(submitData)}
                >
                  Принять
                </button>
              </div>
            )}
          </div>
          <div className={s.author}>
            <div className={s.avatar}>
              <img
                src={
                  appointment?.student?.avatar
                    ? process.env.REACT_APP_STORAGE_URL +
                      appointment?.student?.avatar.image
                    : "/images/default_avatar.png"
                }
                alt=""
              />
            </div>
            <div className={s.content}>
              <h5 className={s.name}>
								{getFullName(appointment?.student)}
              </h5>
              <span className={s.role}>ученик</span>
            </div>
          </div>
        </div>

        <div className={s.text}>{submission.text}</div>
        {submission.attachments?.map((attachment) => {
          if (isImage(attachment)) {
            return (
              <div className={s.img}>
                <img
                  src={process.env.REACT_APP_STORAGE_URL + attachment.file}
                  alt=""
                />
              </div>
            );
          } else {
            return (
              <div className={s.fileWrapper}>
                <p
                  onClick={() =>
                    downloadFile(
                      process.env.REACT_APP_STORAGE_URL + attachment.file
                    )
                  }
                >
                  <div className={s.icon}>
                    <IconFile size={18} />
                  </div>
                  {attachment.name}
                </p>
              </div>
            );
          }
        })}

        {isCommentShown ? (
          <div className={s.commentBlock}>
            <TextareaAutosize
              onChange={(v) => setText(v.target.value)}
              value={text}
              maxRows={5}
              placeholder="Начните печатать здесь...."
            ></TextareaAutosize>
            <div className={s.btns}>
              <Button
                label="Отменить"
                size="small"
                theme={"secondary"}
                onClick={() => {
                  setText("");
                  setIsCommentShown(false);
                }}
              />
              <Button
                label="Отправить"
                size="small"
                onClick={() => reject({ ...submitData, comment: text })}
              />
            </div>
          </div>
        ) : null}

      </div>
			{submission?.comment ? <CommentCard {...{submission, appointment}}/> : null}
    </>
  );
};