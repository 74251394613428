import React, { useContext } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useUpdateProfile } from 'hooks/useUpdateProfile';
import { useProfile } from 'hooks/useProfile';
import { Input } from 'shared/input/input';
import { CalendarInput } from 'shared/calendar-input/calendar-input';
import { Button } from 'shared/button/button';
import s from './settings-form.module.scss'
import { ModalsContext } from '../../layouts/dashboard-layout/dashboard';

export const SettingsForm = () => {
	const {data: profile} = useProfile()
	const {mutate: updateProfile} = useUpdateProfile()
	
	const methods = useForm({
		defaultValues: {...profile, fname: profile.fname || '', lname: profile.lname || ''},
		mode: "onChange"
	})
	const {control, formState} = methods

  const handleSubmit = methods.handleSubmit(data => {
		const d = data.bday ? new Date(data.bday) : null	
		const day = d ? +d.getDate() : null			
		const selectedMonth = d ? +d.getMonth() + 1 : null
		const selectedYear = d ? +d.getFullYear() : null

		const payload = {
			email: data?.email,
			name: data?.name,
			fname: data?.fname ? data?.fname : null,
			lname: data?.lname ? data?.lname : null,
			bday: data?.bday ? `${(day + '').length === 2 ? day : '0' + day}.${(selectedMonth + '').length === 2 ? selectedMonth : '0' + selectedMonth }.${selectedYear}` : null,
		}
		
		updateProfile(payload)
  })

	const newValues = () => {
		const name = methods.watch('name').trim();
		const fname = methods.watch('fname').trim();
		const lname = methods.watch('lname').trim();
		const email = methods.watch('email').trim();
		const bday = methods.watch('bday')
		return name !== profile.name || fname !== profile.fname || lname !== profile.lname || email !== profile.email || bday !== profile.bday
	}

	const { setModal } = useContext(ModalsContext)

	return (
		<FormProvider {...methods}>
			<div className={s.row}>
				<div className={s.partTitle}>
					Конференции
				</div>
				<div className={s.partForm}>
					<Button label='Проверить подключение' className={s.fullBtn} theme="secondary" onClick={() => setModal('check-conf-modal')}/>
				</div>
			</div>
			<form onSubmit={handleSubmit} className={s.form}>
				<div className={s.row}>
					<div className={s.partTitle}>
						Профиль
					</div>
					<div className={s.partForm}>
						<Input
						placeholder='Иван' 
						name='name' 
						label={'Имя'}
						required
						className={s.input}
						validation={{
							required: {
								value: true,
								message: 'Заполните поле',
							},
							minLength: {
								value: 2,
								message: 'Минимум 2 символов',
							},
							maxLength: {
								value: 255,
								message: 'Максимум 255 символов',
							},
							validate: {
								matchPattern: (v) =>
								/^[А-ЯЁ][а-яё]*$/.test(v) ||
									"Имя указано неверно",
							},
						}}/>
					<Input 
						placeholder='Иванов' 
						name='fname' 
						label={'Фамилия'}
						className={s.input}
						validation={{
							required: {
								value: false,
							},
							validate: {
								matchPattern: (v) => {
									if(v?.trim() !== ''){
										return /^[А-ЯЁ][а-яё]*$/.test(v) || "Фамилия указана неверно"
									}
								},
							},
						}}/>
					<Input 
						placeholder='Иванович' 
						name='lname' 
						label={'Отчество'}
						className={s.input}
						validation={{
							required: {
								value: false,
							},
							validate: {
								matchPattern: (v) => {
									if(v?.trim() !== ''){
										return /^[А-ЯЁ][а-яё]*$/.test(v) || "Отчество указано неверно"
									}
								},
							},
						}}/>
						<Controller
							control={control}
							name='bday'
							render={({ field }) => (
								<CalendarInput
									placeholder='01.01.2001'
									onChange={(date) => field.onChange(date)}
									value={field.value}
									label='Дата рождения'
									className={s.input}
								/>
							)}
						/>
						<Button label='Сохранить изменения' className={s.button} disabled={(!formState.isValid && !formState.isDirty) || !newValues()  || formState.isSubmitting} fetching={formState.isSubmitting}/>
					</div>
				</div>
			</form>
		</FormProvider>
	)
}
