import { setAuthToken } from "helpers/tokens";
import { Routes } from "./routes";
import 'react-tooltip/dist/react-tooltip.css'

function App() {
	const token = localStorage.getItem("token");
	if(token) {
		setAuthToken(token);
	}

  return <Routes/>;
}

export default App;
