import React from 'react'
import s from './post-payment-fail.module.scss'
import { Button } from 'shared/button/button';
import { IconCircleXFilled } from '@tabler/icons-react';


export const PostPaymentFail = ({appointment}) => {
	return (
		<div className={s.wrapper} >
			<div className={s.card}>
				<div className={s.content}>
					<IconCircleXFilled size={100} className={s.icon}/>
					<div className={s.title}>Произошла ошибка при оплате</div>
				</div>
				<div className={s.buttons}>
					<Button theme='secondary' label='Вернуться на главную' to={'/'} className={s.btn}/>
					<Button label='Записаться еще' to={`/teacher/${appointment?.teacher_id}`} className={s.btn}/>
				</div>
			</div>
		</div>
	)
}
