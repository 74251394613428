import React from 'react'
import s from './nottinfication.module.scss'
import { FormProvider, useForm } from 'react-hook-form'
import { useProfile } from 'hooks/useProfile';
import { Button } from '../button/button';
import { Toggle } from '../toggle/toggle';
import { useUpdateProfileNotifications } from 'hooks/useUpdateProfileNotifications';
import { profile as profileApi } from 'api/api';

export const Notifications = () => {
	const {data: profile, refetch} = useProfile()
	const {mutate: updateSettings} = useUpdateProfileNotifications()

	const methods = useForm({
		mode: "onChange",
		onChange: () => handleSubmit,
		defaultValues: {
			telegram_notification_new_appointment: profile?.telegram_notification_new_appointment,
			telegram_notification_upcoming_appointment: profile?.telegram_notification_upcoming_appointment,
			telegram_notification_unread_messages: profile?.telegram_notification_unread_messages,
			telegram_notification_new_login: profile?.telegram_notification_new_login,
			telegram_notification_newsletter: profile?.telegram_notification_newsletter,
			email_notification_new_appointment: profile?.email_notification_new_appointment,
			email_notification_new_login: profile?.email_notification_new_login,
			email_notification_newsletter: profile?.email_notification_newsletter,
			telegram_notification_new_homework: profile?.telegram_notification_new_homework,
			telegram_notification_new_homework_submission: profile?.telegram_notification_new_homework_submission,
			telegram_notification_homework_submission_status: profile?.telegram_notification_homework_submission_status,
		}
	})

  const handleSubmit = methods.handleSubmit(data => {
		const payload = {
			telegram_notification_new_appointment: data?.telegram_notification_new_appointment,
			telegram_notification_upcoming_appointment: data?.telegram_notification_upcoming_appointment,
			telegram_notification_unread_messages: data?.telegram_notification_unread_messages,
			telegram_notification_new_login: data?.telegram_notification_new_login,
			telegram_notification_newsletter: data?.telegram_notification_newsletter,
			email_notification_new_appointment: data?.email_notification_new_appointment,
			email_notification_new_login: data?.email_notification_new_login,
			email_notification_newsletter: data?.email_notification_newsletter,
			telegram_notification_new_homework: data?.telegram_notification_new_homework,
			telegram_notification_new_homework_submission: data?.telegram_notification_new_homework_submission,
			telegram_notification_homework_submission_status: data?.telegram_notification_homework_submission_status,
		}
		
		updateSettings(payload)
  })

	const {formState} = methods

	return (
			<div className={s.card}>
					<>
						<div className={s.cardHeader}>Социальные сети и аккаунты</div>
						{profile?.telegram_chat_id ? 
						<Button size='small' theme='secondary' label={'Отвязать Telegram'} onClick={() => {
							profileApi.detachTelegram().then(() => {
								refetch()
							})
						}}/>
						 : 
						<Button rightIcon={<img src='/images/icons/tg.svg'/>} size='small' theme='telegram' label={'Подключить Telegram'} to={'https://t.me/sozvuchno_bot?start=' + profile?.telegram_token} target={'_blank'} className={s.tgBtn}/>
						}
					</>
				<div className={s.cardHeader}>Уведомления</div>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit} className={s.form}>
					{profile?.telegram_chat_id ? <>
							<div className={s.row}>
								<div className={s.partTitle}>
									Telegram
								</div>
								<div className={s.partForm}>
									{profile?.role === 'teacher' && <Toggle label={'Новые занятия'} name='telegram_notification_new_appointment'/>}
									<Toggle label={'Напоминания о предстоящих занятиях'} name='telegram_notification_upcoming_appointment'/>
									<Toggle label={'Непрочитанные сообщения'} name='telegram_notification_unread_messages'/>
									<Toggle label={'Вход в аккаунт'} name='telegram_notification_new_login'/>
									<Toggle label={'Новости сервиса'} name='telegram_notification_newsletter'/>
									{profile?.role === 'teacher' ? <Toggle label={'Новое выполненное ДЗ'} name='telegram_notification_new_homework_submission'/> : 
									<>
										<Toggle label={'Новое домашнее задание'} name='telegram_notification_new_homework'/>
										<Toggle label={'Обновление статуса ДЗ'} name='telegram_notification_homework_submission_status'/>
									</>}
								</div>
							</div>
						</> : null}
						{profile?.email ? <>
							<div className={s.row}>
								<div className={s.partTitle}>
									Почта
								</div>
								<div className={s.partForm}>
									<Toggle label={'Новые занятия'} name='email_notification_new_appointment'/>
									<Toggle label={'Вход в аккаунт'} name='email_notification_new_login'/>
									<Toggle label={'Новости сервиса'} name='email_notification_newsletter'/>
								</div>
							</div>
						</> : null}
						{(profile?.email || profile?.telegram_chat_id) && <Button label='Сохранить изменения' className={s.button} disabled={!formState.isValid || !formState.isDirty || formState.isSubmitting} fetching={formState.isSubmitting}/>}
					</form>
				</FormProvider>
			</div>
	)
}

