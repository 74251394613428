import React from 'react';
import { NavLink } from 'react-router-dom';
import s from './waiting-restore.module.scss';

export const WaitingRestore = () => {
    return (
        <div className={s.wrapper}>
            <div className={s.card}>
                <h2 className={s.title}>
										Восстановление пароля
                </h2>
                <p className={s.message}>
                    Для завершения процесса восстановления, перейдите по ссылке в письме, которое мы отправили на вашу электронную почту.
                </p>
                <p className={s.register}>
                    Если вы не видите письма, проверьте папку "Спам" или <NavLink to={'/resend-confirmation'}>отправьте письмо снова</NavLink>.
                </p>
            </div>
        </div>
    );
};