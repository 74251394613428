import React from "react"
import s from './teacher-card.module.scss'
import ContentLoader from "react-content-loader"

export const Loader = (props) => (
	<>
		{props?.small ? 
			<ContentLoader 
				speed={2}
				width={435}
				height={179}
				viewBox="0 0 435 179"
				className={s.loader}
				backgroundColor="#FAFAFA"
				foregroundColor="#ebebeb"
				{...props}
			>
				 	<rect x="20" y="20" rx="15" ry="15" width="94" height="102" /> 
					<rect x="130" y="20" rx="9" ry="9" width="218" height="18" /> 
					<rect x="130" y="44" rx="9" ry="9" width="255" height="18" /> 
					<rect x="130" y="74" rx="10" ry="10" width="80" height="20" /> 
					<rect x="214" y="74" rx="10" ry="10" width="68" height="20" /> 
					<rect x="130" y="105" rx="9" ry="9" width="40" height="18" /> 
					<rect x="182" y="106" rx="8" ry="8" width="57" height="15" /> 
					<rect x="251" y="106" rx="8" ry="8" width="63" height="15" /> 
					<rect x="20" y="138" rx="11" ry="11" width="113" height="21" />
			</ContentLoader> : 
			<ContentLoader 
				speed={2}
				width={710}
				height={356}
				viewBox="0 0 710 356"
				className={s.loader}
				backgroundColor="#FAFAFA"
				foregroundColor="#ebebeb"
				{...props}
			>
				<rect x="20" y="20" rx="15" ry="15" width="120" height="130" /> 
				<rect x="156" y="20" rx="16" ry="16" width="278" height="31" /> 
				<rect x="156" y="59" rx="16" ry="16" width="185" height="31" /> 
				<rect x="156" y="98" rx="9" ry="9" width="218" height="18" /> 
				<rect x="156" y="132" rx="9" ry="9" width="40" height="18" /> 
				<rect x="208" y="132" rx="9" ry="9" width="100" height="18" /> 
				<rect x="320" y="132" rx="9" ry="9" width="100" height="18" /> 
				<path d="M 20 165.5 h 670" /> 
				<rect x="20" y="182" rx="9" ry="9" width="100" height="18" /> 
				<rect x="20" y="208" rx="7" ry="7" width="589" height="14" /> 
				<rect x="20" y="226" rx="7" ry="7" width="620" height="14" /> 
				<rect x="20" y="244" rx="7" ry="7" width="567" height="14" /> 
				<rect x="20" y="262" rx="7" ry="7" width="599" height="14" /> 
				<rect x="20" y="292" rx="9" ry="9" width="206" height="18" /> 
				<rect x="20" y="318" rx="9" ry="9" width="100" height="18" />
			</ContentLoader>
		}
	</>
)