import React from 'react'
import s from './main.module.scss'
import { Outlet } from 'react-router-dom'
import { Header } from '../header/header';
import { Footer } from '../footer/footer';

export const MainLayout = () => {
	return (
		<div>
			<Header/>
				<main className={s.content}>
					<Outlet/>
				</main>
			<Footer/>
		</div>
	)
}