import classNames from 'classnames'
import React from 'react'
import s from './video-viewer.module.scss'
import ReactPlayer from 'react-player'

export const VideoViewer = ({url, className, local, isOpen, setOpen}) => {

	if(!isOpen) return

	return (
		<div className={classNames(s.videoWrapper, className)}>
			<div className={s.overview} onClick={() => setOpen(false)}></div>
			<ReactPlayer 
				className={s.videoPlayer} 
				controls={true}
				url={local ? url : process.env.REACT_APP_STORAGE_URL + url}/>
		</div>
	)
}
