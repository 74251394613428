import React from 'react'
import s from './calendar.module.scss'
import { formatDate } from 'helpers/formatDate';
import { useGroupedTimeSlots } from 'hooks/useGroupedTimeSlots';

export const Calendar = ({month, selectedDate, selectedInstrument, teacher, set_selectedDate, selectedMonth, selectedYear}) => {
	const getDayNumber = (day) => {
		const dayIndex = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].indexOf(day.toLowerCase());
		return dayIndex !== -1 ? dayIndex : null;
	}

	const rows = Math.ceil((month.length + getDayNumber(month[0].day))/7)
	const groupedTimeSlots = useGroupedTimeSlots(teacher?.timeslots)

	const today = new Date()

	const getCellDate = (date) => {
		let dateStr = formatDate(selectedMonth, date, selectedYear);
		let parts = dateStr.split('-');
		return new Date(parts[2], parts[1] - 1, parts[0]);
	}

	return (
		<table className={s.wrapper}>
			<thead>
				<th>Пн</th>
				<th>Вт</th>
				<th>Ср</th>
				<th>Чт</th>
				<th>Пт</th>
				<th>Сб</th>
				<th>Вс</th>
			</thead>
			<tbody>
			{[...Array(rows).keys()].map((_, index) => 	
				<tr>
					{index === 0 && [...Array(getDayNumber(month[0].day)).keys()].map((_, i) => 
						<td className={(getDayNumber(month[0].day) >= 6 && i > 4) && s.weekend}></td>)}
						{index === 0 ? 
						month.slice(0,  (getDayNumber(month[0].day) - 7) * -1).map(day => {
							const cellDate = getCellDate(day.date)
							const weekday = cellDate.toLocaleDateString('en-us', {weekday: 'long'}).toLowerCase()
							const nonworking = !groupedTimeSlots[weekday];
							const isDisabled = cellDate.getTime() < today.getTime() || nonworking
							const dateString = formatDate(selectedMonth, day.date, selectedYear, '.')
							
							return (
								<td className={`${isDisabled && s.disabled}`}>
									
									<div onClick={() => (!isDisabled && selectedInstrument) && set_selectedDate(dateString)} 
										className={`${selectedDate === dateString && s.active}`}>
										<span>{day.date}</span>
									</div>
								</td>
							);
						}) :
						[...month].splice(index * 7 - getDayNumber(month[0].day), 7).map(day => {
							const cellDate = getCellDate(day.date)
							const weekday = cellDate.toLocaleDateString('en-us', {weekday: 'long'}).toLowerCase()
							const nonworking = !groupedTimeSlots[weekday];
							const isDisabled = nonworking || cellDate.getTime() < today.getTime()
							const dateString = formatDate(selectedMonth, day.date, selectedYear, '.')

							return (
								<td className={`${isDisabled && s.disabled}`}>
									<div onClick={() => (!isDisabled && selectedInstrument) && set_selectedDate(dateString)} 
										className={`${selectedDate === dateString && s.active}`}>
										<span>{day.date}</span>
									</div>
								</td>
							);
						})}
				</tr>
			)}
			</tbody>
		</table>
	)
}
