import s from './student-modal.module.scss'
import { Modal } from 'shared/modal/modal';
import { Loader } from 'shared/loader/loader';
import { Button } from 'shared/button/button';
import { IconMessage } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { getFullName } from '../../../../helpers/getFullname';
import { useStudent } from '../../../../hooks/useStudent';

export const StudentModal = ({isOpen, setOpen, modalParams}) => {
	const studentId = modalParams?.studentId
	const {data: student, isFetching} = useStudent(studentId)

	const navigate = useNavigate()

  return (
		<Modal {...{isOpen, setOpen}} name={'student-modal'}>
			<div className={s.card}>
				{isFetching ? <Loader/> : 
					<div className={s.wrapper}>
						<div className={s.baseInfo}>
							<div className={s.avatar}>
								<img
									src={
										student?.avatar
											? process.env.REACT_APP_STORAGE_URL + student?.avatar.image
											: "/images/default_avatar.png"
									}
									alt=""
								/>
							</div>
							<div className={s.baseInfoContent}>
								<h5 className={s.name}>
									{getFullName(student)}
								</h5>
							</div>
							<Button label={'Написать'} lefticon={<IconMessage/>} size='small' className={s.messageBtn} onClick={() => {
									navigate('/messenger', {state: {id_user_in_chat: student?.id}})
									setOpen()
							}}/>
						</div>
					</div>
					}
			</div>
		</Modal>
  );
}