import { useMutation, useQueryClient } from "@tanstack/react-query"
import { students } from "../api/api"

export const useConnectStudent = (onSuccess = () => {}) => {
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: students.connectStudent,
    onSuccess: () => {
      queryClient.invalidateQueries(['my-students'])
			onSuccess()
    },
  })

	return mutation
}