import React from 'react'
import s from './teacher-profile.module.scss'
import { NavLink, useParams } from 'react-router-dom'
import { IconCheck } from '@tabler/icons-react'
import { Reviews } from 'shared/reviews/reviews';
import { useTeacher } from 'hooks/useTeacher';
import ReactPlayer from 'react-player';
import { getFullName } from '../../helpers/getFullname';

export const TeacherProfile = () => {
	const {teacherId} = useParams()
	const {data: teacher} = useTeacher(teacherId)

	return (
		<>
		<section className={s.firstScreen}>
			<video
				width="100%"
				controls={false}
				autoPlay
				loop
				muted
				className={s.img}
				src='/video/bg.mp4'>
				Your browser doesn't support HTML5 video tag.
			</video>
		</section>
		<section className={s.wrapper}>
			<div className="container">
				<div className={s.flex}>
					<div className={s.content}>
						<div className={s.avatar}>
							<img src={teacher?.avatar
												? process.env.REACT_APP_STORAGE_URL + teacher?.avatar.image
												: "/images/default_avatar.png"} alt=""/>
						</div>
						<div className={s.name}>{getFullName(teacher)}</div>
						<div className={s.instruments}>
							{teacher?.instruments?.map((i) => (
								<div className={s.instrumentTag}>{i.name}</div>
							))}
						</div>
						<NavLink to={'/sign-up'} className={s.button}>Начать заниматься</NavLink>
						{teacher?.teacher_bio && <div className={s.desc}>
							<div className={s.descLabel}>О себе</div>
							<div className={s.descContent}>{teacher?.teacher_bio}</div>
						</div>}
						<div className={s.education}>
								{teacher?.teacher_education?.map((e) => (
										<span className={s.educationContent}>
										<IconCheck className={s.icon} />
										{e}
									</span>
								))}
						</div>
					</div>
					<div className={s.videoCol}>
						{teacher?.video_presentation && <div className={s.video}>
						<ReactPlayer 
								className={s.videoPlayer} 
								controls={true}
								url={process.env.REACT_APP_STORAGE_URL + teacher?.video_presentation}/>
						</div>}
						{teacher?.reviews && <Reviews reviews={teacher?.reviews} rating={teacher?.rating} />}
					</div>
				</div>
			</div>
		</section>
		</>
	)
}
