import s from './view-review-modal.module.scss'
import { Modal } from 'shared/modal/modal';
import { IconStar, IconStarFilled } from "@tabler/icons-react";

export const ViewReviewModal = ({isOpen, setOpen, modalParams}) => {
	const review = modalParams?.review
	const  stars = [1, 2, 3, 4, 5];

  return (
		<Modal {...{isOpen, setOpen}} name={'view-review-modal'}>
			<div className={s.card}>
					<div className={s.reviewTitle}>Общая оценка</div>
					<div className={s.rate}>
						{stars.map((item, index) => (<div  key={'review-star-' + index}>
							{item <= review?.feedback_rating ?  <IconStarFilled className={s.starActive}/> : <IconStar className={s.star}/>}
						</div>))}
					</div>
					<div className={s.areaTitle}>Отзыв</div>
					{review?.feedback_message}
			</div>
		</Modal>
  );
};