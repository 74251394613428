import { FormProvider, useForm } from 'react-hook-form';
import s from './update-password.module.scss'
import { Input } from 'shared/input/input';
import { Button } from 'shared/button/button';
import { profile as profileApi } from 'api/api';
import { toast } from 'react-toastify';

export const UpdatePassword = () => {
	const methods = useForm({
		mode: "onChange"
	})
	const {formState, reset} = methods

  const handleSubmit = methods.handleSubmit(data => {
		const payload = {
			password: data?.password,
			password_confirmation: data?.secondPassword
		}
		
		profileApi.updatePassword(payload).then(res => {
			if(res.status === 200){
				toast.success('Пароль обновлен')
				reset()
			}
		})
  })

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit} className={s.form}>
				<div className={s.row}>
					<div className={s.partTitle}>
						Пароль
					</div>
					<div className={s.partForm}>
						<Input 
							placeholder='Новый пароль' 
							name='password' 
							type="password"
							className={s.input}
							validation={{
								required: {
									value: false,
								},
								minLength: {
									value: 8,
									message: 'Минимум 8 символов',
								},
								maxLength: {
									value: 50,
									message: 'Максимум 50 символов',
								},
							}}/>
						<Input 
							placeholder='Подтвердите пароль' 
							name='secondPassword' 
							type="password"
							className={s.input}
							validation={{
								required: {
									value: false,
								},
								minLength: {
									value: 8,
									message: 'Минимум 8 символов',
								},
								maxLength: {
									value: 50,
									message: 'Максимум 50 символов',
								},
								validate: (val) => {
									if (methods.watch('password') !== val) {
										return "Пароли не совпадают";
									}
								}
							}}/>
						<Button label='Обновить' className={s.button} disabled={!formState.isDirty || !formState.isValid}/>
					</div>
				</div>
			</form>
		</FormProvider>
	)
}
