import React, { useContext, useState } from 'react'
import s from './history.module.scss'
import { HistoryClassCard } from '../../../components/profile/history-class-card/history-class-card';
import { IconChevronLeft, IconChevronRight, IconSortAscending } from '@tabler/icons-react';
import { Loader } from 'shared/loader/loader';
import ReactPaginate from 'react-paginate';
import { ModalsContext } from '../../../components/layouts/dashboard-layout/dashboard';
import { useAppointmentsHistory } from 'hooks/useAppointmentsHistory';
import { useMedia } from 'use-media';

export const History = () => {
	const [page, setPage] = useState(0)


	const {setModal} = useContext(ModalsContext)

	const {data: history, isFetched } = useAppointmentsHistory(page + 1)

	const isMobile = useMedia({maxWidth: '768px'})

	return (
		<div className={s.wrapper}>
			<div className={s.title}>История</div>
			{!isFetched ? <Loader/> : 
			history?.total > 0 ? 
			<div className={s.flex}>
				<table cellSpacing="0" cellPadding="0">
					<thead>
						<th>
							<div className={s.th}>
								Статус
							</div>
						</th>
						<th>
							<div className={s.th}>
								Инструмент
							</div>
						</th>
						<th>
							<div className={s.th}>
								Ученик
							</div>
						</th>
						<th>
							<div className={s.th}>
								Время
							</div>
						</th>
						<th>
							<div className={s.th}>
								Отзывы
							</div>
						</th>
						<th>
							<div className={s.th}>
								Стоимость
							</div>
						</th>
						<th>
							<div className={s.th}>
								Дата занятия
								<button>
									<IconSortAscending size={18}/>
								</button>
							</div>
						</th>
					</thead>
					<tbody>
						{history?.data?.map(cl => (
							<HistoryClassCard {...cl} role='teacher' {...{setModal, isMobile}}/>
						))}
					</tbody>
				</table>
				<ReactPaginate
					breakLabel="..."
					forcePage={page}
					nextLabel={<IconChevronRight size={12}/>}
					onPageChange={(e) => setPage(e.selected )}
					pageRangeDisplayed={5}
					pageCount={history?.total/history?.per_page}
					previousLabel={<IconChevronLeft size={12}/>}
					className={s.paginator}
					previousClassName={s.prev}
					nextClassName={s.next}
					disabledClassName={s.disabled}
					pageLinkClassName={s.item}
					activeLinkClassName={s.active}
				/>
			</div> : <div className={s.empty}>Здесь пока ничего нет</div>
			}
		</div>
	)
}