import React, { useContext } from 'react'
import s from './settings.module.scss'
import { IconEdit } from '@tabler/icons-react'
import { Button } from 'shared/button/button'
import { ModalsContext } from '../../../components/layouts/dashboard-layout/dashboard'
import { useProfile } from 'hooks/useProfile'
import { Notifications } from 'shared/notification-settings/notification'
import { auth } from 'api/api';
import { SettingsForm } from '../../../components/shared/settings-form/settings-form'
import { UpdatePassword } from '../../../components/shared/update-password/update-password'
import { PaymentInput } from '../../../components/shared/payments-input/payment-input'

export const TeacherSettings = () => {
	const {setModal} = useContext(ModalsContext)

	const {data: profile} = useProfile()

	return (
		<div className={s.wrapper}>
			<div className={s.title}>Настройки</div>
			<div className={s.flex}>
				<div className={s.card}>
					<div className={s.profile}>
						<div className={s.avatar}>
							<button className={s.edit} onClick={() => setModal('update-avatar-modal')}>
								<IconEdit size={24} color='#fff'/>
							</button>
							<img src={profile.avatar ? process.env.REACT_APP_STORAGE_URL + profile.avatar.image : '/images/default_avatar.png'} alt="" />
						</div>
						<div className={s.profileContent}>
							<div className={s.name}>{profile?.name}</div>
							<div className={s.role}>{profile?.email}</div>
						</div>
					</div>
					<Button label={'Посмотреть анкету'} className={s.fullBtn} onClick={() => setModal('edit-teacher-profile-modal')}/>
					{/* <PaymentInput/> */}
					<SettingsForm/>
					<UpdatePassword/>
				</div>
				<div className={s.col}>
					<Notifications/>
					<div className={s.card}>
						<button className={s.logout} onClick={() => auth.logout()}>Выйти</button>
					</div>
				</div>
			</div>
		</div>
	)
}