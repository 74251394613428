import React from 'react'
import s from './sidebar.module.scss'
import { NavLink } from 'react-router-dom'
import { IconHeadphones, IconHistory, IconHome, IconInfoCircle, IconLogout, IconMan, IconMenu2, IconMessages, IconSettings } from '@tabler/icons-react'
import classNames from 'classnames'
import { Button } from 'shared/button/button'
import { auth } from 'api/api'
import { useWebSocketContext } from '../../../context/WebSocketProvider'

export const LeftSidebar = ({isHidden, isDynamic, active, className, closeSidebar, profile, isTablet}) => {
	const {chats} = useWebSocketContext()

	return (
		<div className={classNames(className, s.leftSidebar, isTablet ? s.tablet : '', isDynamic ? s.dynamic : '',  isHidden ? s.hidden : null, active ? s.active : null)}>
			<div>
				<div className={s.burgerWrapper}>
					{isHidden && 	<button onClick={() => closeSidebar()} className={s.burger}>
							<IconMenu2/>
						</button>}
					<div className={s.logo}>
						<img src={isTablet ? "/images/logo-tablet.svg" : "/images/logo.svg"} alt="Созвучно - сервис по поиску преподавателей музыки" />
					</div>
				</div>
				<div className={s.menu}>
					<p>Основное меню</p>
					{{'student':<ul>
						<li>
							<NavLink to={'/'} className={({ isActive }) =>  isActive ? s.active : ""}><IconHome/> <span>Главная</span></NavLink>
						</li>
						<li className={classNames(s.messenger, chats?.findIndex(_ => _?.last_message?.sender_id !== profile.id && _?.last_message?.is_read === false) !== -1 ? s.unread : '')}>
							<NavLink to={'/messenger'} className={({ isActive }) =>  isActive ? s.active : ""}><IconMessages/> <span>Сообщения</span></NavLink>
						</li>
						<li>
							<NavLink to={'/teachers'} className={({ isActive }) =>  isActive ? s.active : ""}><IconMan/> <span>Преподаватели</span></NavLink>
						</li>
						<li>
							<NavLink to={'/history'} className={({ isActive }) =>  isActive ? s.active : ""}><IconHistory/><span> История</span></NavLink>
						</li>
					</ul>,
					'teacher':<ul>
						<li>
							<NavLink to={'/'} className={({ isActive }) =>  isActive ? s.active : ""}><IconHome/> <span>Главная</span></NavLink>
						</li>
						<li className={classNames(s.messenger, chats?.findIndex(_ => _?.last_message?.sender_id !== profile.id && _?.last_message?.is_read === false) !== -1 ? s.unread : '')}>
							<NavLink to={'/messenger'} className={({ isActive }) =>  isActive ? s.active : ""}><IconMessages/> <span>Сообщения</span></NavLink>
						</li>
						<li>
							<NavLink to={'/history'} className={({ isActive }) =>  isActive ? s.active : ""}><IconHistory/> <span>История занятий</span></NavLink>
						</li>
					</ul>}[profile?.role]}
					<p>Вспомогательное меню</p>
					<ul>
						<li>
							<NavLink to={'/settings'} className={({ isActive }) =>  isActive ? s.active : ""}><IconSettings/> <span>Настройки</span></NavLink>
						</li>
						<li>
							<NavLink to={'https://t.me/sozvuchno_support_bot'} className={({ isActive }) =>  isActive ? s.active : ""}><IconHeadphones/> <span>Поддержка</span></NavLink>
						</li>
						<li>
							<NavLink to={'/info'} className={({ isActive }) =>  isActive ? s.active : ""}><IconInfoCircle/> <span>Информация</span></NavLink>
						</li>
					</ul>
				</div>
			</div>
			{isTablet ? null : 
			<div className={s.profile}>
				<div className={s.avatar}>
					<img src={profile.avatar ?process.env.REACT_APP_STORAGE_URL + profile.avatar.image : '/images/default_avatar.png'} alt="" />
				</div>
				<div className={s.profileContent}>
					<div className={s.name}>{profile?.name}</div>
					<div className={s.role}>{profile?.role === 'student' ? 'Ученик' : "Преподаватель"}</div>
				</div>
			</div>}
			<Button label={isTablet ? null : 'Выйти'} lefticon={isTablet ? <IconLogout/> : null} className={s.logout} onClick={() => auth.logout()}/>
		</div>
	)
}

