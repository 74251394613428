import React from 'react'
import '@livekit/components-styles';
import {
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
	useTracks,
} from '@livekit/components-react';
import { Track } from 'livekit-client';
import s from './video-conf.module.scss'
import { ControlBar } from './control-bar';
import { Layout } from './layout';

function MyVideoConference() {
  const tracks = useTracks([
		{ source: Track.Source.ScreenShare, withPlaceholder: false },
		{ source: Track.Source.Camera, withPlaceholder: false }
	]);

  return (
		<Layout tracks={tracks}>
			<ParticipantTile/>
		</Layout>
  );
}

export const VideoConf = ({appointment_token}) => {
	return (
		<div className={s.wrapper}>
			<MyVideoConference />
			<RoomAudioRenderer />
			<ControlBar />
		</div>
	)
}