import React from 'react';
import { NavLink } from 'react-router-dom';
import s from './after-registration-teacher.module.scss';

export const AfterRegistrationTeacher = () => {
	return (
			<div className={s.wrapper}>
					<div className={s.card}>
							<h2 className={s.title}>
									Регистрация успешна
							</h2>
							<p className={s.message}>
									Спасибо за регистрацию! В ближайшее время с вами свяжется представитель нашего сервиса для договоренности о созвоне и дальнейших шагах.
							</p>
							<p className={s.register}>
									Если у вас возникнут вопросы или потребуется помощь, вы можете связаться с нами через <NavLink to={'https://t.me/sozvuchno_support_bot'}>контактную информацию</NavLink>.
							</p>
					</div>
			</div>
	);
};