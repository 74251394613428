import React, { useEffect, useState } from 'react'
import { auth } from 'api/api';
import { FormProvider, useForm } from 'react-hook-form';
import { Login } from '../../components/auth/login/login';
import { useSearchParams } from 'react-router-dom';

export const LoginPage = () => {
	const [error, setError] = useState('')

	const [params] = useSearchParams();
	const username = params.get('username')
	const password = params.get('password')

	const methods = useForm({
		defaultValues: {email: username || '', password: password || ''},
		mode: 'onTouched'
	})

	const { formState } = methods

  const handleSubmit = methods.handleSubmit(data => {
		const loginPayload = {
			email: data.email,
			password: data.password
		}
	
		auth.login(loginPayload).catch((response) => {
			setError(response.response)
		})
  })

	useEffect(() => {
		if(username && password){
			const loginPayload = {
				email: username,
				password: password
			}
		
			auth.login(loginPayload).catch((response) => {
				setError(response.response)
			})
		}
	}, [username, password])

	return <FormProvider {...methods}>
		<Login {...{handleSubmit, error, methods, formState}}/>
	</FormProvider>
}