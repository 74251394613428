import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useWebSocket from 'react-use-websocket';
import { useProfile } from 'hooks/useProfile';
import { ChatMessageToast } from '../components/shared/chat-message-toast/chat-message-toast';

const WebSocketContext = createContext();

export const useWebSocketContext = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [socketUrl, setSocketUrl] = useState(`${process.env.REACT_APP_CHAT_WS_URL}/v1/connect?token=${localStorage.getItem("token")}`);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageHistory, setMessageHistory] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
	const [prevSelectedChat, setPrevSelectedChat] = useState(null);

	const hasJWT = () => !!localStorage.getItem("token")
	const navigate = useNavigate()

	const {data: profile} = useProfile()

  const socket = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    onError: () => setSocketUrl(`${process.env.REACT_APP_CHAT_WS_URL}/v1/connect?token=${localStorage.getItem("token")}`),
  }, hasJWT());

	const {lastMessage ,lastJsonMessage ,sendJsonMessage} = socket


  const newMessage = useCallback((data) => {
    setChats((prev) =>
      [...prev].map((el) =>
        el?.id === data?.chat_id ? { ...el, last_message: { ...data } } : el
      )
    );
    if (data?.chat_id === selectedChat?.id) {
      setMessageHistory((prev) => [data, ...prev]);
    }
  });

  const updateMessageIsRead = useCallback((data) => {
    setChats((prev) =>
      [...prev].map((el) =>
        el.id === data.chat_id
          ? { ...el, last_message: { ...el.last_message, is_read: true } }
          : el
      )
    );
    setMessageHistory((prev) =>
      [...prev].map((el) =>
        el.id === data.message_id ? { ...el, is_read: true } : el
      )
    );
  });

  const updateUserStatus = useCallback((data) => {
    setChats((prev) =>
      [...prev].map((chat) => {
        let participant_id = chat.participants.findIndex(
          (el) => el.id === data.user_id
        );
        return participant_id === -1
          ? chat
          : {
              ...chat,
              participants: chat.participants.map((el) =>
                el.id === data.user_id
                  ? { ...el, online: data.status === "ONLINE" }
                  : el
              ),
            };
      })
    );
    if (
      selectedChat?.participants &&
      selectedChat?.participants?.findIndex((el) => el.id === data.user_id) !==
        -1
    ) {
      setSelectedChat((prev) => {
        return {
          ...prev,
          participants: prev.participants.map((el) =>
            el.id === data.user_id
              ? { ...el, online: data.status === "ONLINE" }
              : el
          ),
        };
      });
    }
  });
  
  useEffect(() => {
		if (!lastMessage || !lastJsonMessage) return;
		if (lastJsonMessage.type === "PING") {
			sendJsonMessage({ type: "PONG", data: "" });
		} else if (lastJsonMessage.type === "HELLO") {
			setChats(JSON.parse(lastJsonMessage.data).chats);
		}else if (lastJsonMessage.type === "HISTORY") {
			if (JSON.parse(lastJsonMessage.data).page == 0) {
				setMessageHistory(JSON.parse(lastJsonMessage.data).messages);
			} else {
				setMessageHistory((prev) => [
					...prev,
					...JSON.parse(lastJsonMessage.data).messages,
				]);
			}
			if (JSON.parse(lastJsonMessage.data).messages.length === 0){
				setIsEnd(true);
			}
		} else if (lastJsonMessage.type === "MESSAGE") {
			newMessage(JSON.parse(lastJsonMessage.data));
			if(JSON.parse(lastJsonMessage.data).sender_id !== profile?.id && !window.location.href.includes('messenger')){
				toast(<ChatMessageToast messageData={JSON.parse(lastJsonMessage.data)} chats={chats}/>, {
					closeOnClick: false,
					onClick: () => navigate('/messenger', {state: {id_user_in_chat: JSON.parse(lastJsonMessage.data).sender_id}})
				});
			}
		} else if (lastJsonMessage.type === "MESSAGE_READ") {
			updateMessageIsRead(JSON.parse(lastJsonMessage.data));
		} else if (lastJsonMessage.type === "USER_STATUS_CHANGE") {
			updateUserStatus(JSON.parse(lastJsonMessage.data));
		}
  }, [lastMessage, lastJsonMessage]);

  return (
    <WebSocketContext.Provider value={{ socket, chats, setChats, prevSelectedChat, setPrevSelectedChat, selectedChat, setSelectedChat, messageHistory, setMessageHistory, isEnd, setIsEnd }}>
      {children}
    </WebSocketContext.Provider>
  );
};