import { useQuery } from "@tanstack/react-query"
import { students } from "../api/api";

export const useStudent = (student_id) => {
	return useQuery({
			queryKey: ['student', student_id],
			queryFn: () => students.getStudentById(student_id),
			enabled: !!student_id,
			select: (data) => data.data,
	})
}