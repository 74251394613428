import React, { useCallback, useState } from 'react'
import s from './add-note-card.module.scss'
import TextareaAutosize from 'react-textarea-autosize';
import { Button } from '../../shared/button/button';
import { IconFile, IconPaperclip, IconTrash } from '@tabler/icons-react';
import { formatFileSize } from '../../../helpers/formatFileSize';
import { useDropzone } from 'react-dropzone';
import { useAddAppointmentNote } from '../../../hooks/useAddAppointmentNote';

export const AddNoteCard = ({appointmentId}) => {
	const [text, setText] = useState(null)
	const [myFiles, setMyFiles] = useState([])

	const {mutate: createNote} = useAddAppointmentNote(appointmentId, 
		() => {
			// on success
			setText('')
			setMyFiles([])
		})

  const onDrop = useCallback(acceptedFiles => {
    setMyFiles([...myFiles, ...acceptedFiles])
  }, [myFiles])


	const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
		maxFiles: 5,
		onDrop,
	});

  const removeFiles = (name) => {
    setMyFiles(prev => prev.filter(el => el.name !== name))
  }

	const onClick = () => {
		createNote({appointmentId, 
			data: {
				text
			},
			attachments: acceptedFiles
		})
	}

  return (
    <div className={s.addNoteCard}>
      <TextareaAutosize
        onChange={v => setText(v.target.value)}
        value={text}
				maxRows={5}
        placeholder="Начните печатать здесь...."
      ></TextareaAutosize>
			{myFiles.length >= 1 && 
				myFiles.map(el => (
					<div className={s.fileWrapper}>
						<p>
							<div className={s.icon}>
								<IconFile size={18}/>
							</div>
							{el.name} - {formatFileSize(el.size)}
						</p>
						<button className={s.removeBtn} onClick={() => removeFiles(el.name)}>
							<IconTrash size={18} color={'#F12828'}/>
						</button>
					</div>
				))}
				<div className={s.btns}>
					<input {...getInputProps()} />
      		<Button label={<IconPaperclip size={16}/>} {...getRootProps()} size="small" theme='secondary' className={s.attachmentButton}/>
      		<Button label="Добавить заметку" size="small" className={s.addButton} onClick={onClick}/>
				</div>
    </div>
  );
};

