import React from "react"
import ContentLoader from "react-content-loader"

export const Loader = (props) => (
  <ContentLoader 
    speed={2}
    width={928}
    height={640}
    viewBox="0 0 928 640"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="20" y="20" rx="15" ry="15" width="117" height="126" /> 
    <rect x="153" y="20" rx="16" ry="16" width="278" height="31" /> 
    <rect x="153" y="59" rx="8" ry="8" width="185" height="17" /> 
    <rect x="153" y="128" rx="9" ry="9" width="40" height="18" /> 
    <rect x="205" y="128" rx="9" ry="9" width="100" height="18" /> 
    <rect x="317" y="128" rx="9" ry="9" width="100" height="18" /> 
    <path d="M 20 161.5 h 888" /> 
    <rect x="20" y="194" rx="9" ry="9" width="100" height="18" /> 
    <rect x="20" y="220" rx="7" ry="7" width="408" height="14" /> 
    <rect x="20" y="238" rx="7" ry="7" width="429" height="14" /> 
    <rect x="20" y="256" rx="7" ry="7" width="392" height="14" /> 
    <rect x="20" y="274" rx="7" ry="7" width="415" height="14" /> 
    <rect x="20" y="304" rx="9" ry="9" width="206" height="18" /> 
    <rect x="20" y="330" rx="9" ry="9" width="100" height="18" /> 
    <rect x="499" y="211" rx="16" ry="16" width="93" height="70" /> 
    <rect x="499" y="289" rx="9" ry="9" width="114" height="18" /> 
    <rect x="499" y="315" rx="9" ry="9" width="72" height="18" /> 
    <rect x="650" y="211" rx="9" ry="9" width="244" height="18" /> 
    <rect x="650" y="237" rx="9" ry="9" width="244" height="18" /> 
    <rect x="650" y="263" rx="9" ry="9" width="244" height="18" /> 
    <rect x="650" y="289" rx="9" ry="9" width="244" height="18" /> 
    <rect x="650" y="315" rx="9" ry="9" width="244" height="18" /> 
    <path d="M 499 354.5 h 394.74" /> 
    <rect x="499" y="373" rx="16" ry="16" width="47" height="47" /> 
    <rect x="556" y="373" rx="7" ry="7" width="62" height="14" /> 
    <rect x="556" y="397" rx="7" ry="7" width="100" height="14" /> 
    <rect x="554" y="428" rx="7" ry="7" width="62" height="14" /> 
    <path d="M 499 463.483 h 394.74" /> 
    <rect x="499" y="486" rx="16" ry="16" width="47" height="47" /> 
    <rect x="556" y="486" rx="7" ry="7" width="62" height="14" /> 
    <rect x="556" y="510" rx="7" ry="7" width="100" height="14" /> 
    <rect x="554" y="541" rx="7" ry="7" width="62" height="14" /> 
    <rect x="684" y="579" rx="8" ry="8" width="224" height="41" />
  </ContentLoader>
)