import { useMutation, useQueryClient } from "@tanstack/react-query"
import { appointment } from "../api/api"

export const useRejectHomework = (appointmentId, onSuccess = () => {}) => {
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: appointment.rejectHomework,
    onSuccess: () => {
      queryClient.invalidateQueries(['appointment', appointmentId])
			onSuccess()
    },
  })

	return mutation
}