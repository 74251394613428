import React from 'react'
import s from './appointment-note.module.scss'
import { downloadFile } from '../../../helpers/downloadFile'
import { IconFile } from '@tabler/icons-react'

export const AppointmentNote = ({note}) => {
	const isImage = (attachment) => {
		const type = attachment?.name.split('.').pop()
		return type === 'png' || type === 'jpeg' || type === 'jpg'
	}

	return (
		<div className={s.contentBlock}>
			<div className={s.text}>{note.text}</div>
			{note.attachments?.map(attachment => {
				if(isImage(attachment)){
					return <div className={s.img}>
						<img src={process.env.REACT_APP_STORAGE_URL + attachment.file} alt="" />
					</div>
				}else{
				return (
					<div className={s.fileWrapper}>
						<p onClick={() => downloadFile(process.env.REACT_APP_STORAGE_URL + attachment.file)}>
							<div className={s.icon}>
								<IconFile size={18}/>
							</div>
							{attachment.name}
						</p>
					</div>)
				}
			})}
		</div>
	)
}