import { useMutation, useQueryClient } from "@tanstack/react-query"
import { profile } from "api/api"
import { toast } from "react-toastify"

export const useUpdateProfileAvatar = () => {
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: (data) => profile.updateProfileAvatar(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['profile'])
			toast.success('Данные обновлены')
    },
  })

	return mutation
}