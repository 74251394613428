import React, { useContext, useState } from "react";
import s from "./homework-card.module.scss";
import { downloadFile } from "../../../helpers/downloadFile";
import { IconFile, IconPencil, IconTrash } from "@tabler/icons-react";
import { ModalsContext } from "../../layouts/dashboard-layout/dashboard";
import { isImage } from "../../../helpers/isImage";
import { EditNote } from "../edit-note-card/edit-note-card";
import { SubmissionCard } from "../homework-submission-card/homework-submission-card";

export const HomeworkCard = ({ homework, appointmentId, appointment }) => {
  const [isEditable, setIsEditable] = useState(false);
  const { setModal } = useContext(ModalsContext);

  if (isEditable) {
    return (
      <EditNote
        {...{ homework, appointmentId }}
        onSuccess={() => setIsEditable(false)}
      />
    );
  }

  return (
    <>
      <div className={s.contentBlock}>
        <div className={s.text}>{homework.task}</div>
        {homework.attachments?.map((attachment) => {
          if (isImage(attachment)) {
            return (
              <div className={s.img}>
                <img
                  src={process.env.REACT_APP_STORAGE_URL + attachment.file}
                  alt=""
                />
              </div>
            );
          } else {
            return (
              <div className={s.fileWrapper}>
                <p
                  onClick={() =>
                    downloadFile(
                      process.env.REACT_APP_STORAGE_URL + attachment.file
                    )
                  }
                >
                  <div className={s.icon}>
                    <IconFile size={18} />
                  </div>
                  {attachment.name}
                </p>
              </div>
            );
          }
        })}
        <div className={s.btns}>
          <button className={s.editBtn} onClick={() => setIsEditable(true)}>
            <IconPencil />
          </button>
          <button
            className={s.removeBtn}
            onClick={() =>
              setModal("remove-homework", {appointmentId, homeworkId: homework.id})
            }
          >
            <IconTrash />
          </button>
        </div>
      </div>
      {homework?.submissions?.map((submission) => (
        <SubmissionCard {...{ submission, appointment, homework }} />
      ))}
    </>
  );
};
