import React from 'react'
import { useParams } from 'react-router-dom'
import { PostPaymentFail } from '../../../components/profile/post-payment-fail/post-payment-fail'
import { Loader } from 'shared/loader/loader';
import { useAppointment } from 'hooks/useAppointment';

export const PostPaymentPageFail = () => {
	const {appointmentId} = useParams()
	const { data: appointment, isLoading } = useAppointment(appointmentId)

  if (isLoading) return <Loader/>;
	
	return <PostPaymentFail appointment={appointment}/>
}
