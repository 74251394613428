import { IconPaperclip } from "@tabler/icons-react";
import s from './chat.module.scss'
import { useEffect, useId, useRef } from "react";
import classNames from "classnames";

export const Upload = ({ 
  onUpload,
  className,
	file
}) => {
	const form = useRef(null)
  
  const handleFile = (file) => {
		if (!file) return;
		
		onUpload(file);
  };

  const handleFileChange = (event) => {
    handleFile(event.target.files[0]);
  };

	useEffect(() => {
		if(!file){
			form.current.reset()
		}
	}, [file])

  const input = useRef();

  const id = useId()
  return (
		<form ref={form}>
      <label htmlFor={id} className={classNames(s.attachmentsBtn, className)}>
        <input
          ref={input}
          type="file"
          className={s.input}
          onChange={handleFileChange}
          id={id}
        />
        <IconPaperclip size={20} />
      </label>
		</form>
  );
};