import React from 'react'
import s from './messenger.module.scss'
import { Chat } from '../../../components/profile/chat'

export const Messenger = () => {
	return (
		<div className={s.wrapper}>
			<div className={s.title}>Сообщения</div>
			<Chat className={s.chat}/>
		</div>
	)
}
