import s from './add-review-modal.module.scss'
import { Modal } from 'shared/modal/modal';
import { IconStar, IconStarFilled } from "@tabler/icons-react";
import { Button } from 'shared/button/button';
import { useEffect, useState } from 'react';
import { useAddReview } from 'hooks/useAddReview';

export const AddReviewModal = ({isOpen, setOpen, modalParams}) => {
	const [text, setText] = useState('')
	const [rate, setRate] = useState(0)

	const  stars = [1, 2, 3, 4, 5];

	const {mutate: addReview} = useAddReview()

	const onAddReview = () => {
		addReview({
			body: {
				rating: rate,
				message: text.trim()
			}, 
			id: modalParams?.appointmentId})
		setOpen()
	}

	useEffect(() => {
		if(!isOpen){
			setText('')
			setRate(0)
		}
	}, [isOpen])

  return (
		<Modal {...{isOpen, setOpen}} name={'add-review-modal'}>
			<div className={s.card}>
					<div className={s.reviewTitle}>Общая оценка</div>
					<div className={s.rate}>
						{
							stars.map((item) => (
								<button className={s.starButton} onClick={() => setRate(item)} key={item}>
									{item <= rate ?  <IconStarFilled className={s.starActive}/> : <IconStar className={s.star}/>}
								</button>
							))
						}
					</div>
					<div className={s.areaTitle}>Ваш отзыв</div>
					<textarea
						className={s.textarea}
						placeholder="Текст..."
						value={text}
						onChange={(e) => setText(e?.currentTarget?.value)}
					/>
					<Button
						disabled={!(rate && text.trim())}
						className={s.btn}
						onClick={onAddReview}
						label={'Отправить'}
					/>
			</div>
		</Modal>
  );
};