import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import s from './text-collapse.module.scss';

export const TextCollapse = ({ className, maxHeight, children, hide = true }) => {
    const [isHide, setIsHide] = useState(hide); 
		const [needCollapse, setNeedCollapse] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            const contentHeight = contentRef.current.scrollHeight;
            setNeedCollapse(contentHeight > maxHeight);
        }
    }, [children, maxHeight]);

    const toggleVisibility = () => {
        setIsHide(!isHide);
    };


    return (
        <div className={classNames(className, s.wrapper)}>
            <div ref={contentRef} className={classNames(s.content, { [s.hide]: isHide, [s.show]: !isHide })} style={{ maxHeight: isHide ? maxHeight : 'none' }}>
                {children}
            </div>
						{needCollapse && (
                <button className={s.toggleButton} onClick={toggleVisibility}>
                    {isHide ? 'Показать больше' : 'Скрыть'}
                </button>
            )}
        </div>
    );
};