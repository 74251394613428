import React from 'react'
import s from './homework-comment-card.module.scss'
import { getFullName } from '../../../helpers/getFullname';

export const CommentCard = ({submission, appointment}) => {
	function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  return (
      <div className={s.commentBlock}>
        <div className={s.header}>
          <div className={s.jcsb}>
            <span className={s.date}>{formatDate(submission.updated_at)}</span>
          </div>
          <div className={s.author}>
            <div className={s.avatar}>
              <img
                src={
                  appointment?.teacher?.avatar
                    ? process.env.REACT_APP_STORAGE_URL +
                      appointment?.teacher?.avatar.image
                    : "/images/default_avatar.png"
                }
                alt=""
              />
            </div>
            <div className={s.content}>
              <h5 className={s.name}>
								{getFullName(appointment.teacher)}
              </h5>
              <span className={s.role}>преподаватель</span>
            </div>
          </div>
        </div>

        <div className={s.text}>{submission.comment}</div>
      </div>
  );
}
