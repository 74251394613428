import React from "react";
import s from "./classCard.module.scss";
import { IconCalendarTime } from "@tabler/icons-react";
import classNames from "classnames";
import { Button } from "../button/button";

export const NextClassCard = ({
  id,
  teacher,
  student,
  starts_at,
  role = 'student',
  instrument,
  className
}) => {
  const date = new Date(starts_at);
  const today = new Date();

  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  return (
    <div className={classNames(className, s.nextClassCard)}>
      <div className={s.content}>
        <div className={s.instrument}>{instrument.name}</div>
        <div className={classNames(s.info, s[role])}>
          <div className={s.time}>
            <IconCalendarTime color="#A7AEB8" size={20} />
            <span className={isToday ? classNames(s.date, s.today) : s.date}>
              {isToday
                ? "Сегодня"
                : date.getDate() + " " + months[date.getMonth()]}
            </span>
            |
            <span>
              {date.getHours() +
                ":" +
                (date.getMinutes() === 0 ? "00" : date.getMinutes())}
              -
              {date.getHours() +
                ":50"}
            </span>
          </div>
          <div className={s.teacher}>
            <div className={s.avatar}>
              {role === "student" ? (
                <img
                  src={
                    teacher.avatar
                      ? process.env.REACT_APP_STORAGE_URL + teacher.avatar.image
                      : "/images/default_avatar.png"
                  }
                  alt=""
                />
              ) : (
                <img
                  src={
                    student?.avatar
                      ? process.env.REACT_APP_STORAGE_URL + student?.avatar?.image
                      : "/images/default_avatar.png"
                  }
                  alt=""
                />
              )}
            </div>
            <span>{role === "student" ? teacher?.name : student?.name}</span>
          </div>
        </div>
      </div>
			<div>
				<Button
					label={"Перейти к занятию"}
					size="small"
					to={"/appointment/" + id}
				/>
			</div>
    </div>
  );
};
