import { Track } from 'livekit-client';
import * as React from 'react';
import { DisconnectButton, MediaDeviceMenu, TrackToggle, useLocalParticipantPermissions, usePersistentUserChoices } from '@livekit/components-react';
import s from './video-conf.module.scss'

export function ControlBar({
  controls,
  saveUserChoices = true,
  ...props
}) {
  const visibleControls = { leave: true, ...controls };

  const localPermissions = useLocalParticipantPermissions();

  if (!localPermissions) {
    visibleControls.camera = false;
    visibleControls.chat = false;
    visibleControls.microphone = false;
  } else {
    visibleControls.camera ??= localPermissions.canPublish;
    visibleControls.microphone ??= localPermissions.canPublish;
    visibleControls.chat ??= localPermissions.canPublishData && controls?.chat;
  }

  const {
    saveAudioInputEnabled,
    saveVideoInputEnabled,
    saveAudioInputDeviceId,
    saveVideoInputDeviceId,
  } = usePersistentUserChoices({ preventSave: !saveUserChoices });

  const microphoneOnChange = React.useCallback(
    (enabled, isUserInitiated) =>
      isUserInitiated ? saveAudioInputEnabled(enabled) : null,
    [saveAudioInputEnabled],
  );

  const cameraOnChange = React.useCallback(
    (enabled, isUserInitiated) =>
      isUserInitiated ? saveVideoInputEnabled(enabled) : null,
    [saveVideoInputEnabled],
  );

  return (
    <div {...props} className={s.controlBar}>
			<div className={s.togglesGroup}>
				{visibleControls.microphone && (
					<div className="lk-button-group">
						<TrackToggle
							source={Track.Source.Microphone}
							showIcon
							onChange={microphoneOnChange}
						/>
						<div className="lk-button-group-menu">
							<MediaDeviceMenu
								kind="audioinput"
								onActiveDeviceChange={(_kind, deviceId) => saveAudioInputDeviceId(deviceId ?? '')}
							/>
						</div>
					</div>
				)}
				{visibleControls.camera && (
					<div className="lk-button-group">
						<TrackToggle source={Track.Source.Camera} showIcon onChange={cameraOnChange}/>
						<div className="lk-button-group-menu">
							<MediaDeviceMenu
								kind="videoinput"
								onActiveDeviceChange={(_kind, deviceId) => saveVideoInputDeviceId(deviceId ?? '')}
							/>
						</div>
					</div>
				)}
			</div>
      {visibleControls.leave && (
        <DisconnectButton>
          Выйти
        </DisconnectButton>
      )}
    </div>
  );
}
