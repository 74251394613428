import React, { useContext, useEffect, useState } from 'react'
import s from './home.module.scss'
import { ClassesBoard } from '../../../components/teacher-profile/classes-board/classes-board';
import { ProfileInfoCard } from '../../../components/teacher-profile/profile-info-card/profile-info-card';
import { NextClassCard } from 'shared/classCard/nextClassCard';
import { NotNextClassCard } from 'shared/classCard/notNextClassCard';
import { ModalsContext } from '../../../components/layouts/dashboard-layout/dashboard';
import { useProfile } from 'hooks/useProfile';
import { Loader } from 'shared/loader/loader';
import { useAllAppointments } from '../../../hooks/useAllAppointments';
import { useUpcomingAppointments } from '../../../hooks/useUpcomingAppointments';
import { endOfWeek, startOfWeek } from 'date-fns';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { StudentsList } from '../../../components/teacher-profile/students-list/students-list';
import { useMyStudents } from '../../../hooks/useMyStudents';

export const TeacherHome = () => {
	const date = new Date()

	const {setModal} = useContext(ModalsContext)
	const [start_date, set_start_date] = useState();
  const [end_date, set_end_date] = useState();
	
	const {data: profile} = useProfile()
	const {data: analytics} = useAnalytics()
	const {data: students} = useMyStudents()

	const { data: appointments, isFetched: appointmentsIsFetched } =
    useAllAppointments({
      from: start_date?.toISOString(),
      to: end_date?.toISOString(),
    });
	const {data: upcoming, isFetched: isUpcomingFetched} = useUpcomingAppointments()

	// Функция для получения начала и конца текущей недели
	const getCurrentWeekDates = () => {
		const from = startOfWeek(new Date(date));
		const to = endOfWeek(new Date(date))

		set_start_date(from);
		set_end_date(to);
};

	useEffect(() => {
			getCurrentWeekDates();
	}, []);

	return (
		<div className={s.wrapper}>
			<div className={s.grid}>
				<div className={s.flex}>
					<ProfileInfoCard {...{profile}} analytics={[
						{
							label: 'Проведенно уроков', 
							value: analytics?.appointments_count
						},
						{
							label: 'Оценка', 
							value: profile?.rating
						},
						]}/>
					{isUpcomingFetched ? 
						(upcoming ? 
							<NextClassCard className={s.nextClassCard} role="teacher" {...upcoming}/> :
							<NotNextClassCard className={s.nextClassCard} role="teacher"/>) :
						<div className={s.nextClassLoader}>
							<Loader/>
						</div>
					}
				</div>
				<ClassesBoard className={s.classes} {...{appointments, setModal, set_end_date, appointmentsIsFetched, set_start_date, start_date, end_date}} isTeacher={true}/>
				<StudentsList students={students} className={s.students}/>
			</div>
		</div>
	)
}
