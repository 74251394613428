import React, { useCallback, useContext, useState } from 'react'
import s from './homework-card.module.scss'
import { downloadFile } from '../../../helpers/downloadFile'
import { IconFile, IconPaperclip, IconTrash } from '@tabler/icons-react'
import { Button } from '../../shared/button/button';
import { useDropzone } from 'react-dropzone';
import TextareaAutosize from 'react-textarea-autosize';
import { useUpdateHomework } from '../../../hooks/useUpdateHomework';
import { useAddHomeworkSubmission } from '../../../hooks/useAddHomeworkSubmission';
import { isImage } from '../../../helpers/isImage';
import { useAppointment } from '../../../hooks/useAppointment';
import classNames from 'classnames';
import { CommentCard } from '../../shared/homework-comment-card/homework-comment-card';
import { getFullName } from '../../../helpers/getFullname';

const Submission = ({homework, onSuccess, appointmentId}) => {
	const [text, setText] = useState(null)
	const [myFiles, setMyFiles] = useState([])

	const {mutate: submit} = useAddHomeworkSubmission(appointmentId, onSuccess)

  const onDrop = useCallback(acceptedFiles => {
    setMyFiles([...myFiles, ...acceptedFiles])
  }, [myFiles])


	const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
		maxFiles: 5,
		onDrop,
	});

  const removeFiles = (name) => {
    setMyFiles(prev => prev.filter(el => el.name !== name))
  }

	const onClick = () => {
		submit({appointmentId, homeworkId: homework.id, 
			data: {
				text
			},
			attachments: acceptedFiles,
		})
	}
	const onCancel = () => {
		onSuccess()
	}

  return (
    <div className={s.addNoteCard}>
      <TextareaAutosize
        onChange={v => setText(v.target.value)}
        value={text}
				maxRows={5}
        placeholder="Начните печатать здесь...."
      ></TextareaAutosize>
			{myFiles.length >= 1 && 
				myFiles.map(el => (
					<div className={s.fileWrapper}>
						<p>
							<div className={s.icon}>
								<IconFile size={18}/>
							</div>
							{el.name} - {el.size}
						</p>
						<button className={s.removeBtn} onClick={() => removeFiles(el.name)}>
							<IconTrash size={18} color={'#F12828'}/>
						</button>
					</div>
				))}
			<div className={s.jcsb}>
					<Button
						label="Отменить"
						size="small"
						theme={"secondary"}
						onClick={onCancel}
					/>
					<div className={s.btns}>
						<input {...getInputProps()} />
						<Button label={<IconPaperclip size={16}/>} {...getRootProps()} size="small" theme='secondary' className={s.attachmentButton}/>
						<Button label="Сохранить" size="small" onClick={onClick} />
					</div>
				</div>
    </div>
  );
};

const SubmissionCard = ({submission, appointment}) => {
	function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

	return (
		<>
			<div className={s.contentBlock}>
				<div className={s.submissionHeader}>
					<div className={s.jcsb}>
						<span className={s.date}>{formatDate(submission.created_at)}</span>
						<span className={classNames(s.status, s[submission.status])}>
							{{
								'rejected': 'Отклонено',
								'approved': 'Принято',
								'created': 'Ожидает ответа',
							}[submission.status]}
						</span>
					</div>
					<div className={s.author}>
						<div className={s.avatar}>
							<img
								src={
									appointment?.student?.avatar
										? process.env.REACT_APP_STORAGE_URL + appointment?.student?.avatar.image
										: "/images/default_avatar.png"
								}
								alt=""
							/>
						</div>
						<div className={s.content}>
							<h5 className={s.name}>
								{getFullName(appointment?.student)}
							</h5>
							<span className={s.role}>ученик</span>
						</div>
					</div>
				</div>
				
				<div className={s.text}>{submission.text}</div>
				{submission.attachments?.map(attachment => {
					if(isImage(attachment)){
						return <div className={s.img}>
							<img src={process.env.REACT_APP_STORAGE_URL + attachment.file} alt="" />
						</div>
					}else{
					return (
						<div className={s.fileWrapper}>
							<p onClick={() => downloadFile(process.env.REACT_APP_STORAGE_URL + attachment.file)}>
								<div className={s.icon}>
									<IconFile size={18}/>
								</div>
								{attachment.name}
							</p>
						</div>)
					}
				})}
			</div>
			{submission?.comment ? <CommentCard {...{submission, appointment}}/> : null}
		</>
	)
}

export const HomeworkCard = ({homework, appointmentId, appointment}) => {
	const [submissionIsActive, setSubmissionIsActive] = useState(false)

	return (
		<>
			<div className={s.contentBlock}>
				<div className={s.text}>{homework.task}</div>
				{homework.attachments?.map(attachment => {
					if(isImage(attachment)){
						return <div className={s.img}>
							<img src={process.env.REACT_APP_STORAGE_URL + attachment.file} alt="" />
						</div>
					}else{
					return (
						<div className={s.fileWrapper}>
							<p onClick={() => downloadFile(process.env.REACT_APP_STORAGE_URL + attachment.file)}>
								<div className={s.icon}>
									<IconFile size={18}/>
								</div>
								{attachment.name}
							</p>
						</div>)
					}
				})}
				{!submissionIsActive ? <Button label="Добавить результат" size="small" className={s.addButton} onClick={() => setSubmissionIsActive(true)}/> : null}
			</div>
			{submissionIsActive ? <Submission {...{homework, appointmentId}} onSuccess={() => setSubmissionIsActive(false)}/> : null}
			{homework.submissions.map(submission => <SubmissionCard {...{submission, appointment}}/>)}
		</>
	)
}