import s from './remove-appointment.module.scss'
import { Modal } from 'shared/modal/modal';
import { Button } from 'shared/button/button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { classes } from 'api/api';

export const RemoveAppointmentModal = ({isOpen, setOpen, modalParams}) => {
	const appointmentId = modalParams?.appointmentId
	
	const queryClient = useQueryClient()
	const mutation = useMutation({
		mutationFn: (data) => classes.cancelClass(data),
		onSuccess: () => {
      queryClient.invalidateQueries(['appointments'])
    },
	})
	
	const cancelAppointment = () => {
		mutation.mutate({id: appointmentId, reason: 'Reason'})
		setOpen()
	}

  return (
		<Modal {...{isOpen, setOpen}} name={'remove-appointment'}>
			<div className={s.card}>
					<div className={s.title}>Вы уверены?</div>
					<div className={s.text}>Вы точно хотете отменить занятие?</div>
					<div className={s.btns}>
						<Button
							theme='secondary'
							className={s.btn}
							onClick={() => setOpen()}
							label={'Нет'}
						/>
						<Button
							className={s.btn}
							onClick={cancelAppointment}
							label={'Да'}
						/>
					</div>
			</div>
		</Modal>
  );
};