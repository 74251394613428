import React, { useContext, useState } from 'react'
import s from './teachers.module.scss'
import { TeacherCard } from '../../../components/profile/teacher-card/teacher-card';
import { Loader } from '../../../components/profile/teacher-card/loader';
import { SearchTeacher } from '../../../components/profile/search-teacher/search-teacher';
import { FormProvider, useForm } from 'react-hook-form';
import { ModalsContext } from '../../../components/layouts/dashboard-layout/dashboard';
import { useAllTeachers } from 'hooks/useAllTeachers';
import { useAllInstruments } from 'hooks/useAllInstruments';
import { Loader as Spinner } from 'shared/loader/loader';
import { useMedia } from 'use-media';

export const Teachers = () => {
	const {setModal} = useContext(ModalsContext)
	const [filters, setFilters] = useState()

	const{data: teachers, isFetched} = useAllTeachers(filters)
	const{data: instruments} = useAllInstruments()
	
	const methods = useForm()
	const {control, handleSubmit} = methods

	const isMobile = useMedia({maxWidth: '768px'})

  const onSubmit = handleSubmit(data => {
		const payload = {
			q: data?.q,
			instruments: data?.instruments?.map((instrument) => instrument.value),
		}
		
		setFilters({...payload})
  })

	return (
		<div className={s.wrapper}>
			<div className={s.title}>Преподаватели</div>
			<FormProvider {...methods}>
				<SearchTeacher {...{onSubmit, control, instruments}}/>
			</FormProvider>
			{!isFetched ? 
					<div className={s.groupTeachers}>
						<p className={s.groupTitle}>
							Все преподаватели
						</p>
						<div className={s.flex}>
							{isMobile ?  
								<Spinner/> :
								<>
									<Loader small/>
									<Loader small/>
								</>}
						</div>
					</div> : 
					<>
					{teachers?.my?.data && <div className={s.groupTeachers}>
						<p className={s.groupTitle}>
							Мои преподаватели
						</p>
						<div className={s.flex}>
							{teachers?.my?.data?.map((teacher) => (
								<TeacherCard {...{...teacher}} small openModal={setModal}/>
							))}
						</div>
					</div>}
					{teachers?.all?.data && <div className={s.groupTeachers}>
						<p className={s.groupTitle}>
							Все преподаватели
						</p>
						<div className={s.flex}>
							{teachers?.all?.data?.map((teacher) => (
								<TeacherCard {...{...teacher}} small openModal={setModal}/>
							))}
						</div>
					</div>}
					</>
					}
		</div>
	)
}

