import React from 'react'
import s from './checkbox.module.scss'
import { useFormContext } from 'react-hook-form'
import { IconSquare, IconSquareCheckFilled } from '@tabler/icons-react'

export const Checkbox = ({name, className, value, label, checked}) => {
	const {register} = useFormContext()

	return (
			<div className={`${s.wrapper} ${className}`}>
				<label>
					<input
					type='checkbox'
					name={name}
					value={value}
					checked={checked}
					{...register(name)}/>
					<span className={s.label}>
						<IconSquare className={s.checkboxIconEmpty}/>
						<IconSquareCheckFilled className={s.checkboxIconFill}/>
						{label}
					</span>
				</label>
			</div>
	)
}