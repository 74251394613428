import React, { createContext, useEffect, useState } from 'react'
import s from './dashboard.module.scss'
import { Outlet, useLocation } from 'react-router-dom'
import { Modals } from '../../profile/modals/index';
import { Loader } from 'shared/loader/loader';
import classNames from 'classnames'
import { useProfile } from 'hooks/useProfile';
import { useMedia } from 'use-media';
import { LeftSidebar } from '../sidebar/sidebar';
import { DashboardHeader } from '../dashboard-header/dashboard-header'
import { DashboardBottombar } from '../dashboard-bottombar/dashboard-bottombar';

export const ModalsContext = createContext()

export const Dashboard = () => {
	const [showSidebar, set_showSidebar] = useState(false)
	const [isOpen, setOpen] = useState();
	const [modalParams, setModalParams] = useState(null)

	const {pathname} = useLocation()

	const {data: profile, isFetched } = useProfile()

	const isTablet = useMedia({maxWidth: '1312px'})
	const isMobile = useMedia({maxWidth: '768px'})

	useEffect(() => {
		set_showSidebar(false)
	}, [pathname])


	const setModal = (name, params) => {
		setModalParams(params)
		setOpen(name)
	}

	return (
		<ModalsContext.Provider value={{isOpen, setModal}}>
			<div className={s.wrapper}>
				{!isFetched ? 
				<Loader/> :
				<>
					<Modals isOpen={isOpen} setOpen={setOpen} modalParams={modalParams} setModalParams={setModalParams}/>
					<div className={s.content}>
						{isMobile ? null : 
						<>
							<div className={s.hiddenSidebar}>
								<div className={classNames(s.bgSidebar, showSidebar ? s.active : null)} onClick={() => set_showSidebar(false)}></div>
								<LeftSidebar {...{profile}} isHidden={true} active={showSidebar} closeSidebar={() => set_showSidebar(false)} isDynamic={true}/>
							</div>
							<LeftSidebar {...{profile, isTablet}}/>
						</>}
						<div className={s.dashboardLeftCol}>
							{(isTablet && !isMobile) ? <DashboardHeader {...{set_showSidebar, profile, isMobile}}/> : null}
							<div className={s.contentWrapper}>
								<Outlet/>
							</div>
						</div>
					</div>
					{isMobile ? <DashboardBottombar/> : null}
				</>}
			</div>
		</ModalsContext.Provider>
	)
}