import React, { useCallback, useEffect, useState } from "react";
import s from "./chat.module.scss";
import classNames from "classnames";
import { Messenger } from "./messanger";
import { chat } from "api/api";
import { useProfile } from "hooks/useProfile";
import { useWebSocketContext } from '../../../context/WebSocketProvider';
import { useMedia } from "use-media";
import { useLocation } from "react-router-dom";

export const Chat = ({className}) => {
	const { socket, chats,prevSelectedChat, setPrevSelectedChat, selectedChat, setSelectedChat, messageHistory, isEnd, setIsEnd } = useWebSocketContext()

	const isMobile = useMedia({maxWidth: '768px'})

	const {state} = useLocation()
	const idUserInChat = state?.id_user_in_chat

  const [text, setText] = useState("");
  const [emojiShow, setEmojiShow] = useState(false)
  const [page, setPage] = useState(0);
  const [file, setFile] = useState(null);

  const { sendJsonMessage } = socket;

  const { data: profile } = useProfile();

  const getSmallDateString = (timestamp) => {
    if (!timestamp) return null;
    const d = new Date(timestamp * 1000);
    const today = new Date();
    const tdStr = today.toLocaleDateString();
    const str = d.toLocaleDateString();
    if (str === tdStr) {
      return (
        d.toLocaleTimeString().split(":")[0] +
        ":" +
        d.toLocaleTimeString().split(":")[1]
      );
    } else {
      return str;
    }
  };

  useEffect(() => {
    if (prevSelectedChat !== selectedChat) {
      setPage(0);
      setIsEnd(false);
    }
    if (selectedChat) {
      const data = {
        type: "HISTORY_REQUEST",
        data: JSON.stringify({
          chat_id: selectedChat.id,
          page: page,
        }),
      };

      sendJsonMessage(data);
			if(page === 0){
				setPage(1)
			}
    }
    setPrevSelectedChat(selectedChat);
  }, [selectedChat, page]);

  const sendMessage = (id, text, file) => {
    const data = {
      type: "MESSAGE",
      data: JSON.stringify({
        chat_id: selectedChat.id,
        data: text,
        attachments: file
          ? [
              {
                file: file.file,
                id: file.id,
              },
            ]
          : null,
      }),
    };

    sendJsonMessage(data);
    setText("");
    setFile(null);
  };

  const handleEnter = useCallback(() => {
    if (text?.trim() || file) {
      if (file) {
        const uploading = chat.postAttachment(file);
        uploading
          .then((res) => {
            sendMessage(selectedChat.id, text, res.data);
            return;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        sendMessage(selectedChat.id, text);
      }
			setEmojiShow(false)
    }
  }, [text, selectedChat, file]);

  const fetchMoreData = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const onRead = (id, chat_id, user_id) => {
    const data = {
      type: "MESSAGE_READ",
      data: JSON.stringify({
        message_id: id,
        chat_id,
      }),
    };

    sendJsonMessage(data);
  };

	useEffect(() => {
    if (idUserInChat) {
      setSelectedChat(
        chats?.find((el) => el.participants[0]?.id === idUserInChat)
      );
      window.history.replaceState({}, "");
    }
  }, [idUserInChat]);

  return (
    <div className={classNames(className, s.card, isMobile ? s.mobile : "")}>
      {(!selectedChat || !isMobile) && (
        <div className={s.chatSidebar}>
          <div className={s.chatScroller}>
            <div className={s.chatsList}>
              {chats?.length > 0 &&
                chats?.map((chat) => (
                  <div
                    className={classNames(
                      s.chatCard,
                      selectedChat?.id === chat?.id ? s.active : ""
                    )}
										key={'chat-key-' + chat?.id}
                    onClick={() => setSelectedChat(chat)}
                  >
                    <div
                      className={classNames(
                        s.avatarWrapper,
                        chat?.participants[0]?.online && s.online
                      )}
                    >
                      <div className={s.avatar}>
                        <img
                          src={chat?.participants[0]?.role === 'service' ?  // для сервисного аккаунта
													"/logo192.png" :
													(chat?.participants[0]?.avatar
                              ? process.env.REACT_APP_STORAGE_URL +
                                chat?.participants[0]?.avatar
                              : "/images/default_avatar.png")
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className={s.chatCardContent}>
                      <div className={s.jcsb}>
                        <div className={s.name}>
                          {chat?.participants[0]?.name}
                        </div>
                        <div className={s.date}>
                          {getSmallDateString(chat?.last_message?.timestamp)}
                        </div>
                      </div>
                      <div className={s.jcsb}>
                        <div className={s.status}>
                          {chat?.last_message?.data
                            ? chat?.last_message?.data.slice(0, 20)
                            : "Нет сообщений"}
                        </div>
                        {chat?.last_message?.sender_id !== profile.id &&
                          chat?.last_message?.is_read === false && (
                            <div className={s.unread}></div>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
			{(!isMobile || selectedChat) && <Messenger
				chat={selectedChat || null}
				{...{
					isEnd,
					setSelectedChat,
					onRead,
					handleEnter,
					fetchMoreData,
					text,
					setText,
					messageHistory,
					profile,
					file,
					setFile,
					isMobile,
					emojiShow, 
					setEmojiShow
				}}
			/>}
    </div>
  );
};
