import React, { useEffect, useState } from 'react'
import s from './chat-message-toast.module.scss'

export const ChatMessageToast = ({messageData, chats}) => {
	const [user, setUser] = useState()

	useEffect(() => {
		setUser(null)
		if(chats && messageData){
			setUser(chats?.find(el => el.id === messageData.chat_id)?.participants[0])
		}
	}, [chats, messageData])

	return (
		<div className={s.wrapper}>
			<div className={s.avatar}>
				<img src={user?.role === 'service' ? '/logo192.png' :
					(user?.avatar ? process.env.REACT_APP_STORAGE_URL + user?.avatar : '/images/default_avatar.png')} alt="" />
			</div>
			<div className={s.content}>
				<div className={s.name}>
					{user?.name}
				</div>
				<div className={s.message}>
					{messageData?.data}
				</div>
			</div>
		</div>
	)
}
